import { useReactiveVar } from '@apollo/client'
import { backendV2 } from '@graphql/ClientGraphql'
import { useCallback, useEffect, useState } from 'react'
import { GET_ACCOUNT, ProfileData, ProfileVars } from '../graphql/nftfy/backend/profile/Profile'
import { accountVar } from '../graphql/variables/WalletVariable'
import { formatShortAddress } from '../services/UtilService'
import { ProfileType } from '../types/UserProfileTypes'
import useBreakpoints from './useBreakpoints'

export const useAccount = (accountParam?: string) => {
  const accountLogged = useReactiveVar(accountVar)
  const account = accountParam || accountLogged

  const { isMobile } = useBreakpoints()
  const formatted = !isMobile ? formatShortAddress(String(account), 5, -5) : formatShortAddress(String(account), 5)
  const [user, setUser] = useState<ProfileType | null>(null)

  const getProfile = useCallback(async () => {
    const handleProfile = async (accountValid: string) => {
      const { data } = await backendV2.query<ProfileData, ProfileVars>({
        query: GET_ACCOUNT,
        variables: {
          walletAddress: accountValid?.toLocaleLowerCase()
        }
      })
      setUser(data.profile)
    }
    account && handleProfile(account)
  }, [account])

  useEffect(() => {
    getProfile()
  }, [getProfile])
  return {
    account,
    name: user?.domain || user?.username || (account ? formatted : account),
    image: user?.avatarUrl,
    user,
    refetch: getProfile
  }
}
