import erc20Abi from '@abi/erc20.json'
import ethTokenIcon from '@assets/tokens/ethereum.svg'
import nftfyTokenIcon from '@assets/tokens/nftfy.svg'
import { graphQlClient } from '@graphql/ClientGraphql'
import {
  UserFracsByOwnerDataV2,
  UserFracsByOwnerVarsV2,
  USER_FRACS_BY_OWNER_QUERY_V2
} from '@graphql/nftfy/deprecated/marketplace/userFrac/UserFracsByOwner'
import { WalletProvider } from '@graphql/variables/WalletVariable'
import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import { chainConfig } from '../config'
import { code } from '../messages'
import { MarketplaceItem } from '../types/MarketplaceTypes'
import { WalletERC20Share } from '../types/WalletTypes'
import { erc721Service } from './Erc721Service'
import { initializeWeb3 } from './MultiWalletService'
import { notifyError } from './NotificationService'
import { isAllowedChain, scale } from './UtilService'

interface WalletService {
  getERC20Shares(account: string, chainId: number, version?: number, offset?: number): Promise<WalletERC20Share[]>
  getERC20SharesByAddress(walletAddress: string, erc20Address: string, chainId: number): Promise<WalletERC20Share | undefined>
}

export const walletService = (providerName: WalletProvider): WalletService => {
  switch (providerName) {
    case WalletProvider.api:
      return apiProvider()

    case WalletProvider.theGraph:
      return theGraphProvider()

    default:
      return apiProvider()
  }
}

const apiProvider = (): WalletService => {
  return {
    getERC20Shares(): Promise<WalletERC20Share[]> {
      // TODO: Implement on api provider
      return Promise.all([])
    },
    getERC20SharesByAddress(): Promise<WalletERC20Share | undefined> {
      // TODO: Implement on api provider
      return Promise.resolve(undefined)
    }
  }
}

const theGraphProvider = (): WalletService => {
  return {
    getERC20Shares: async (account: string, chainId: number, version: 1 | 2, offset: number): Promise<WalletERC20Share[]> => {
      try {
        const config = chainConfig(chainId)

        if (!config || !config.fracionalizerV1_2.theGraph || !config.fracionalizerV1.theGraph) {
          return []
        }

        const client = await graphQlClient(config.fracionalizerV1_2.theGraph)

        const executeQuery = async () => {
          const queryV2 = await client.query<UserFracsByOwnerDataV2, UserFracsByOwnerVarsV2>({
            query: USER_FRACS_BY_OWNER_QUERY_V2,
            variables: {
              owner: account,
              orderDirection: 'desc',
              orderField: 'id',
              skip: offset
            }
          })

          if (!queryV2) {
            // TODO: After change chainIdVar to be <number | undefined>, insert again 'notifyError(code[5015], error)'
            return []
          }

          const { data, error } = queryV2

          if (error || !data || !data.userFracs) {
            // TODO: After change chainIdVar to be <number | undefined>, insert again 'notifyError(code[5015], error)'
            return []
          }

          return data.userFracs.map(userFrac => ({
            ...userFrac,
            frac: {
              ...userFrac.frac,
              exitPrice: userFrac.frac.reservePrice
            }
          }))
        }

        const erc20SharesItems = await executeQuery()

        const erc20Shares: Promise<WalletERC20Share>[] = erc20SharesItems.map(async userFrac => {
          // TODO: Implement on the Graph the get721Item()
          const erc721Item = await erc721Service(chainId).getErc721ItemMetadata(
            userFrac.frac.target.collection.id,
            userFrac.frac.target.tokenId
          )

          const metadata = erc721Item?.metadata

          return {
            ...userFrac,
            ...userFrac.frac,
            metadata: metadata || undefined
          }
        })

        return await Promise.all(erc20Shares)
      } catch (e) {
        notifyError(code[5015], e)

        return []
      }
    },
    getERC20SharesByAddress(): Promise<WalletERC20Share | undefined> {
      // TODO: Implement on The Graph provider
      return Promise.resolve(undefined)
    }
  }
}

export const getErc20Balance = async (
  walletAddress: string,
  erc20Address: string,
  erc20Decimals: number,
  chainId: number,
  erc20Symbol?: string
): Promise<BigNumber> => {
  if (!chainId) {
    return new BigNumber(0)
  }

  const config = chainConfig(chainId)
  if (!config || !isAllowedChain(chainId)) {
    throw new Error('GetErc20Balance: no config or is Allowed Chain ')
  }

  const { networkTokenAddress, networkTokenSymbol } = config

  const web3 = initializeWeb3(chainId, true)

  if (erc20Address === networkTokenAddress || (erc20Symbol && erc20Symbol === networkTokenSymbol)) {
    const balance = await web3.eth.getBalance(walletAddress)
    return scale(new BigNumber(balance), -18)
  }

  const contractERC20 = new web3.eth.Contract(erc20Abi as AbiItem[], erc20Address)
  try {
    const balance = await contractERC20.methods.balanceOf(walletAddress).call()
    return scale(new BigNumber(balance), -erc20Decimals)
  } catch (error) {
    return new BigNumber(0)
  }
}

export const addMetamaskCustomToken = (erc20: MarketplaceItem, chainId: number) => {
  const web3 = initializeWeb3(chainId, false)

  web3.givenProvider.sendAsync({
    method: 'metamask_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: erc20.id,
        symbol: erc20.symbol,
        decimals: erc20.decimals,
        image: erc20.metadata?.image
      }
    }
  })
}
export const getAssetLogo = (address: string, chainId: number): string => {
  if (Web3.utils.toChecksumAddress(address) === Web3.utils.toChecksumAddress('0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c')) {
    return nftfyTokenIcon
  }

  if (Web3.utils.toChecksumAddress(address) === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
    return ethTokenIcon
  }

  const config = chainConfig(chainId)

  if (!config) {
    throw new Error('No Config')
  }

  if (address.toLowerCase() === config.networkTokenAddress.toLowerCase()) {
    return config.networkTokenIcon
  }

  const web3 = initializeWeb3(chainId, true)
  try {
    if (config.assets.includes(address.toLowerCase())) {
      return `https://raw.githubusercontent.com/balancer-labs/assets/master/assets/${address.toLowerCase()}.png`
    }
    if (address.toLowerCase() === '0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd') {
      return 'https://raw.githubusercontent.com/balancer-labs/assets/master/assets/0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd.png'
    }
    if (address.toLowerCase() === '0x6fcb6408499a7c0f242e32d77eb51ffa1dd28a7e') {
      return 'https://raw.githubusercontent.com/balancer-labs/assets/master/assets/0x6fcb6408499a7c0f242e32d77eb51ffa1dd28a7e.png'
    }
    if (address.toLowerCase() === '0xffffffff2ba8f66d4e51811c5190992176930278') {
      return 'https://raw.githubusercontent.com/balancer-labs/assets/master/assets/0xffffffff2ba8f66d4e51811c5190992176930278.png'
    }
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${web3.utils.toChecksumAddress(
      address
    )}/logo.png`
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return ''
  }
}

export const addErc20ToMetamask = (
  erc20Address: string,
  erc20Decimals: number,
  erc20Symbol: string,
  erc20ImageUrl: string,
  chainId: number
) => {
  const web3 = initializeWeb3(chainId, false)

  web3.givenProvider.sendAsync({
    method: 'metamask_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: erc20Address,
        symbol: erc20Symbol,
        decimals: erc20Decimals,
        image: erc20ImageUrl || `${window.location.href}/assets/nftfy.svg`
      }
    }
  })
}

export const addNftFyToMetamask = (chainId: number) => {
  const web3 = initializeWeb3(chainId, false)

  web3.givenProvider.sendAsync({
    method: 'metamask_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
        symbol: 'NFTFY',
        decimals: 18,
        image: `${window.location.href}/assets/nftfy.svg`
      }
    }
  })
}
