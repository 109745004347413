import { notifyError } from '@services/NotificationService'
import { RcFile as file } from 'antd/lib/upload'
import axios from 'axios'
import { globalConfig } from '../config'

export interface UserData {
  accountId: string
  chainId: string
  username?: string
  email?: string
  description?: string
  website?: string
  instagram?: string
  telegram?: string
  twitter?: string
  domain?: string
}

export interface UserProfileService {
  updateUser(data: UserData): Promise<void>
  updateImage(account: string, photo: file, type: 'avatar' | 'cover'): Promise<void>
  updateImageMetadata(account: string, type: 'avatar' | 'cover'): Promise<void>
  getWalletAddress(userName: string): Promise<string>
}

export const userProfileService = (): UserProfileService => {
  const { nftfyBackend } = globalConfig
  return {
    updateImage: async (account: string, photo: file, type: 'avatar' | 'cover') => {
      const formData = new FormData()

      formData.append('accountId', account)
      formData.append('photo', photo)
      formData.append('type', type)
      await axios.post(`${nftfyBackend.bucketUrl}/profile/upload`, formData)
    },
    updateUser: async (data: UserData) => {
      return axios.post(`${nftfyBackend.bucketUrl}/profile`, data)
    },
    updateImageMetadata: async (account: string, type: 'avatar' | 'cover') => {
      try {
        await axios.post(`${nftfyBackend.bucketUrl}/profile/media`, {
          accountId: account,
          type
        })
      } catch (error) {
        notifyError('', error, true)
      }
    },
    getWalletAddress: async (username: string) => {
      const { data } = await axios.get<string>(`${nftfyBackend.bucketUrl}/profile/slug/${username}`)
      return data
    }
  }
}
