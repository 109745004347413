import Head from 'next/head'
import { ReactNode, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Footer } from '../layout/footer/Footer'

import { WalletErrorAlert } from '../WalletErrorAlert'

export type DefaultTemplatePageProps = {
  alertWallet?: boolean
  children: ReactNode
  background?: string
  noMargin?: boolean
  fullWidth?: boolean
  skipMetaTags?: boolean
  header: ReactNode
}

export function DefaultPageTemplate({
  alertWallet,
  children,
  header,
  background,
  noMargin,
  fullWidth,
  skipMetaTags
}: DefaultTemplatePageProps) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {!skipMetaTags && (
        <Head>
          <title>
            Nftfy - This product will be discontinued soon. Explore our new product that will be launched in 2023 and get early access by
            completing the form.
          </title>
          <meta
            property='title'
            content='Nftfy - This product will be discontinued soon. Explore our new product that will be launched in 2023 and get early access by completing the
            form.'
          />
          <meta
            name='description'
            content='This product will be discontinued soon. Explore our new product that will be launched in 2023 and get early access by completing the
        form.'
          />
          <meta property='og:type' content='website' />
          <meta property='og:url' content='https://nftfy.org/' />
          <meta property='og:title' content='Nftfy' />
          <meta
            property='og:description'
            content='This product will be discontinued soon. Explore our new product that will be launched in 2023 and get early access by completing the
            form.'
          />
          <meta property='og:image' content='https://nftfy.org/nftfy.jpg' />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:url' content='https://nftfy.org/' />
          <meta name='twitter:title' content='Nftfy' />
          <meta
            name='twitter:description'
            content='This product will be discontinued soon. Explore our new product that will be launched in 2023 and get early access by completing the
            form.'
          />
          <meta name='twitter:image' content='https://nftfy.org/nftfy.jpg' />
        </Head>
      )}
      {alertWallet && <WalletErrorAlert />}
      <Main background={background} noMargin={!!noMargin}>
        {header}
        <Container fullWidth={!!fullWidth}>{children}</Container>
        <Footer />
      </Main>
    </>
  )
}
export const { Main, Container } = {
  Main: styled.main<{ background?: string; noMargin: boolean }>`
    display: grid;
    grid-template-rows: 88px auto minmax(447px, 593px);
    row-gap: 36px;
    width: 100%;
    min-height: 100vh;
    background: ${props => props.theme.colors.white};

    ${props =>
      props.background &&
      css`
        background: ${props.background};
      `}

    ${props =>
      css`
        padding: ${props.noMargin ? 0 : props.theme.margin.small};
        padding-top: 0;
        padding-bottom: 0;
      `}
    

    @media (min-width: ${props => props.theme.viewport.tablet}) {
      padding: ${props => props.theme.margin.medium};
      ${props =>
        css`
          padding: ${props.noMargin ? 0 : props.theme.margin.medium}};
          padding-top: 0;
          padding-bottom: 0;
        `}
    }

    @media (min-width: ${props => props.theme.viewport.desktop}) {
      padding: ${props => props.theme.margin.large};
      grid-template-rows: 80px auto 280px;
      ${props =>
        css`
          padding: ${props.noMargin ? 0 : props.theme.margin.medium};
          padding-top: 0;
          padding-bottom: 0;
        `}
    }

    @media (min-width: ${props => props.theme.viewport.desktopXl}) {
      padding: ${props => props.theme.margin.large};
      ${props =>
        css`
          padding: ${props.noMargin ? 0 : props.theme.margin.large};
          padding-top: 0;
          padding-bottom: 0;
        `}
    }

    .infinite-scroll-component {
      vertical-align: top !important;
    }
  `,
  Container: styled.div<{ fullWidth?: boolean }>`
    width: 100%;
    margin: 0 auto;
    ${props =>
      !props.fullWidth &&
      css`
        max-width: 1480px;
      `}
  `
}
