import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import styled from 'styled-components'
import AccountButton from '../../shared/buttons/AccountButton'
import Icon, { Icons } from '../../shared/Icon'
import HeaderMenu from './HeaderMenu'
import HeaderProductMenu, { ProductMenu } from './HeaderProductMenu'

interface HeaderDesktopProps {
  logo: {
    image: string
    name: string
    href: string
  }
  menu: { name: string; href: string; activeHref: string[] }[]
  productMenu: ProductMenu[]
}

export default function HeaderDesktop({ logo, menu, productMenu }: HeaderDesktopProps) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuHover, setMenuHover] = useState(false)

  return (
    <Nav>
      <Container>
        <div>
          <Link href={logo.href}>
            <a>
              <Image width={106} height={36} src={logo.image} />
            </a>
          </Link>
          <div>|</div>
          <div>{`${logo.name}`}</div>
        </div>
        <div>
          <HeaderMenu menu={menu} />
        </div>
        <div>
          <HeaderProductMenu menu={productMenu} open={menuOpen} close={() => setMenuOpen(false)} />
          <ProductMenuButton
            onClick={() => setMenuOpen(!menuOpen)}
            onMouseEnter={() => setMenuHover(true)}
            onMouseLeave={() => setMenuHover(false)}
          >
            {menuOpen && <Icon name={Icons.productMenuActive} size={32} />}
            {!menuOpen && <Icon name={menuHover ? Icons.productMenuHover : Icons.productMenu} size={32} />}
          </ProductMenuButton>
          <AccountButton />
        </div>
      </Container>
    </Nav>
  )
}

const { Nav, Container, ProductMenuButton } = {
  Nav: styled.nav`
    display: grid;
    grid-template-columns: minmax(0, 1480px);
    grid-template-rows: 80px;
    align-items: center;
    justify-content: center;

    img {
      cursor: pointer;
    }
  `,
  Container: styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1.6rem;
    position: relative;
    z-index: 8;

    div {
      display: grid;
      align-items: center;
    }

    > div:nth-child(1) {
      display: grid;
      grid-template-columns: 106px auto auto;
      gap: 1.6rem;

      div {
        font-size: 1.6rem;
      }

      div:nth-child(2) {
        font-family: ${props => props.theme.fonts.primary};
        color: ${props => props.theme.colors.gray[2]};
        font-size: 300;
        cursor: default;
      }

      div:nth-child(3) {
        color: ${props => props.theme.colors.gray[2]};
        cursor: default;
      }
    }

    > div:nth-child(2) {
      display: grid;
      justify-self: flex-end;
    }

    > div:nth-child(3) {
      display: grid;
      grid-template-columns: 52px auto;
      gap: 0.8rem;
    }
  `,
  ProductMenuButton: styled.div`
    height: 52px;
    width: 52px;
    padding: 1rem;
    cursor: pointer;
  `
}
