import discordIcon from '@assets/icons/social-network/discordIcon.svg'
import githubIcon from '@assets/icons/social-network/githubIcon.svg'
import linkedInIcon from '@assets/icons/social-network/linkedInIcon.svg'
import medium from '@assets/icons/social-network/medium.svg'
import telegramIcon from '@assets/icons/social-network/telegramIcon.svg'
import twitterIcon from '@assets/icons/social-network/twitterIcon.svg'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

export const FooterMenuSocial: React.FC = () => {
  const handleLinkClick = async (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <S.FooterMenu>
      <S.LinkItem onClick={() => handleLinkClick('https://www.linkedin.com/company/nftfy/')} target='_blank'>
        <Image src={linkedInIcon.src} alt='linkedIn' width={24} height={24} layout='fixed' />
      </S.LinkItem>
      <S.LinkItem onClick={() => handleLinkClick('https://bit.ly/3xdGR4k')} target='_blank'>
        <Image src={discordIcon.src} alt='discord' width={24} height={24} layout='fixed' />
      </S.LinkItem>
      <S.LinkItem onClick={() => handleLinkClick('https://twitter.com/nftfyofficial')} target='_blank'>
        <Image src={twitterIcon.src} alt='twitter' width={24} height={24} layout='fixed' />
      </S.LinkItem>
      <S.LinkItem onClick={() => handleLinkClick('https://github.com/nftfy')} target='_blank'>
        <Image src={githubIcon.src} alt='github' width={24} height={24} layout='fixed' />
      </S.LinkItem>
      <S.LinkItem onClick={() => handleLinkClick('https://t.me/nftfySec')} target='_blank'>
        <Image src={telegramIcon.src} alt='telegram' width={24} height={24} layout='fixed' />
      </S.LinkItem>
      <S.LinkItem onClick={() => handleLinkClick('https://medium.com/nftfy')} target='_blank'>
        <Image src={medium.src} alt='medium' width={24} height={24} layout='fixed' />
      </S.LinkItem>
    </S.FooterMenu>
  )
}

const S = {
  FooterMenu: styled.nav`
    display: flex;
    gap: 16px;
    @media (max-width: ${props => props.theme.viewport.desktop}) {
      margin: 0 auto;
    }
  `,
  LinkItem: styled.a`
    transition: transform 0.2s;
    :hover {
      transform: scale(1.3);
    }
    img {
      filter: ${props => (props.theme.colors.black !== props.theme.colors.constants.white ? 'invert(1)' : 'invert(0)')};
    }
  `
}
