import crowdPadIcon from '@assets/icons/product-menu/crowdpad.svg'
import marketplaceIcon from '@assets/icons/product-menu/marketplace.svg'
import rockPoolIcon from '@assets/icons/product-menu/rockpool.svg'
import { ProductMenu } from '../components/design-system/header/HeaderProductMenu'

export function productMenu(chainId: number): ProductMenu[] {
  return [
    {
      name: 'Marketplace',
      href: `/marketplace/${chainId}`,
      activeHref: ['/marketplace/[chainId]'],
      description: 'Fractionalize NFTs, create value and start your own DAO with Fractions.',
      enabled: true,
      icon: marketplaceIcon
    },
    {
      name: 'RockPool',
      href: `/rockpool/1`,
      activeHref: ['/rockpool/[chainId]'],
      description: 'Call your friends to buy the coolest NFTs on OpenSea right here, collectively.',
      enabled: true,
      icon: rockPoolIcon
    },
    {
      name: 'CrowdPad',
      href: `/crowdpad/${chainId}`,
      activeHref: ['/crowdpad/[chainId]'],
      description: 'Make your own crowdfunding and sell your NFTs to crowds.',
      enabled: true,
      icon: crowdPadIcon
    }
    // {
    //   name: 'DAO Tools',
    //   href: '#',
    //   activeHref: [],
    //   description: 'Make and manage DAOs Governance',
    //   enabled: false,
    //   icon: daoIcon
    // },
    // {
    //   name: 'Rewards Programs',
    //   href: '#',
    //   activeHref: [],
    //   description: 'Earn rewards in our rewards program',
    //   enabled: false,
    //   icon: rewardsIcon
    // }
  ]
}
