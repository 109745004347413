import styled, { css } from 'styled-components'
import { chainConfig } from '../../../config'
import Icon, { IconEnum } from '../Icon'

interface DefaultButtonProps {
  label?: string
  color?: 'primary' | 'success' | 'default'
  size?: 'default'
  width?: number
  height?: number
  boldText?: boolean
  disabled?: boolean
  currentChainId?: number
  rightChainId?: number
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  htmlType?: 'submit' | 'button' | 'reset'
  loading?: boolean
  className?: string
  icon?: IconEnum
  element?: React.ReactNode
  iconSize?: number
}

interface ButtonProps {
  size: 'default'
}

export const DefaultButton = ({
  label,
  size = 'default',
  width,
  height,
  boldText,
  color = 'default',
  disabled,
  currentChainId,
  rightChainId,
  onClick,
  onMouseEnter,
  onMouseLeave,
  htmlType = 'button',
  loading,
  className,
  icon,
  element,
  iconSize
}: DefaultButtonProps) => {
  const isWrongNetwork = currentChainId && rightChainId && currentChainId !== rightChainId
  const changeNetwork = async () => {
    await (window.ethereum as { request: (args: { method: string; params: any }) => Promise<string> }).request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${Number(rightChainId).toString(16)}` }]
    })
    16
  }

  const handleEventClick = () => {
    if (isWrongNetwork) {
      changeNetwork()
      return
    }
    onClick && onClick()
  }

  const { fantasyName } = chainConfig(rightChainId || 1)
  return (
    <S.Button
      className={`${loading && 'loading'} ${disabled && 'disabled'} ${className}`}
      size={size}
      disabled={disabled}
      color={color}
      style={{ maxWidth: `${width}px` || 'auto', maxHeight: `${height}px` || 'auto' }}
      onClick={() => handleEventClick()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type={isWrongNetwork ? 'button' : htmlType}
    >
      {isWrongNetwork ? (
        <>
          <span className={`${boldText && 'bold-text'}`}>{`Change Network to ${fantasyName}`}</span>
        </>
      ) : (
        <>
          {loading ? (
            <span className={`${boldText && 'bold-text'} loading`}>Loading...</span>
          ) : (
            <span className={`${boldText && 'bold-text'}`}>
              {!!icon && !!iconSize && <Icon name={icon} size={iconSize || 12} />}
              {!icon && !!element && element}
              {label}
            </span>
          )}
        </>
      )}
    </S.Button>
  )
}

const S = {
  Button: styled.button<ButtonProps>`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    padding: 13px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    gap: 8px;
    transition: background-color 125ms ease-in !important;

    > span {
      font-size: 20px;
      line-height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      &.bold-text {
        font-weight: 500;
      }
    }

    &.loading,
    &.disabled {
      background: ${props => props.theme.colors.gray[2]};
      cursor: not-allowed;
      &:hover,
      &:active,
      &:focus {
        background: ${props => props.theme.colors.gray[2]};
      }
    }

    ${props =>
      props.color === 'primary' &&
      css`
        background: ${props.theme.colors.blue.main};
        > span {
          color: ${props.theme.colors.constants.white};
        }

        &:hover,
        &:active,
        &:focus {
          background: ${props.theme.colors.blue.dark};
          > span {
            color: ${props.theme.colors.constants.white};
          }
        }
      `}

    ${props =>
      props.color === 'default' &&
      css`
        background: none;
        border: 1px solid ${props.theme.colors.gray[3]};
        > span {
          color: ${props.theme.colors.gray[4]};
        }
        &:hover,
        &:active,
        &:focus {
          background: ${props.theme.colors.gray[1]};
          > span {
            color: ${props.theme.colors.gray[4]};
          }
        }
      `}

      ${props =>
      props.color === 'success' &&
      css`
        background: ${props.theme.colors.green.main};
        > span {
          color: ${props.theme.colors.constants.white};
        }

        &:hover,
        &:active,
        &:focus {
          background: ${props.theme.colors.green.dark};
          > span {
            color: ${props.theme.colors.constants.white};
          }
        }
      `}
  `,
  Icon: styled.img`
    margin-left: 8px;
  `
}
