import { gql } from '@apollo/client'
import { Erc721Attribute } from '@appTypes/UtilTypes'

export interface NftByWalletVar {
  ownerAddress: string
  offset: number
  limit: number
  chainIdList: number[]
}

export interface NftData {
  selected?: boolean
  tokenId: string
  contract_type?: string
  address: string
  ownerAddress?: string
  name: string
  symbol: string
  chainId: number
  creatorAddress?: string
  nftsCount?: number
  metadata?: {
    name: string
    image: string
    imageFull: string
    description: string
    animation_url: string
    social_media?: string
    web_site_url?: string
    twitter?: string
    telegram?: string
    discord?: string
    instagram?: string
    author?: string
    animationType?: string
    attributes?: Erc721Attribute[] | Record<string, unknown>
    asset_contract?: {
      name: string
      schema_name: string
    }
  }
}

export interface NftByWalletData {
  erc721ByOwner: NftData[]
}

export const NFTS_BY_WALLET_QUERY = gql`
  query Erc721ByWallet($chainIdList: [Int!]!, $ownerAddress: String!, $limit: Int, $offset: Int!) {
    erc721ByOwner(chainIdList: $chainIdList, ownerAddress: $ownerAddress, limit: $limit, offset: $offset) {
      chainId
      address
      name
      nftsCount
      metadata {
        name
        animation_url
        imageFull
        image
        description
        attributes {
          value
          trait_type
        }
        web_site_url
        twitter
        totalSupply
        telegram
        social_media
        properties {
          total_supply {
            type
            description
          }
          preview_media_file_type2 {
            type
            description
          }
          preview_media_file_type {
            type
            description
          }
          preview_media_file2_type {
            type
            description
          }
          preview_media_file2 {
            type
            description
          }
          preview_media_file {
            type
            description
          }
          name {
            type
            description
          }
          description {
            type
            description
          }
          created_at {
            type
            description
          }
        }
        owner
        instagram
        discord
        author
        asset_contract {
          schema_name
          name
        }
        animation_url
        animationType
      }
      ownerAddress
      symbol
      tokenId
      contract_type
    }
  }
`
