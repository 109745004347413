import { gql } from '@apollo/client'
import { Erc721Item } from '@appTypes/WalletTypes'

export interface Erc721ItemMetadataVarsV2 {
  chainId: number
  contractAddress: string
  tokenId: string
}

export interface Erc721ItemMetadataDataV2 {
  erc721: Erc721Item
}

export const ERC721_ITEM_METADATA = gql`
  query Erc721WithOwnerById($chainId: Int!, $contractAddress: String!, $tokenId: String!) {
    erc721(chainId: $chainId, contractAddress: $contractAddress, tokenId: $tokenId) {
      address
      chainId
      name
      nftsCount
      ownerAddress
      symbol
      tokenId
      metadata {
        animationType
        animation_url
        asset_contract {
          schema_name
          name
        }
        attributes {
          value
          trait_type
        }
        author
        description
        discord
        image
        imageFull
        instagram
        name
        owner
        properties {
          total_supply {
            type
            description
          }
          preview_media_file_type2 {
            type
            description
          }
          preview_media_file_type {
            type
            description
          }
          preview_media_file2_type {
            description
            type
          }
          preview_media_file2 {
            type
            description
          }
          preview_media_file {
            type
            description
          }
          name {
            type
            description
          }
          description {
            type
            description
          }
          created_at {
            type
            description
          }
        }
        social_media
        telegram
        totalSupply
        twitter
        web_site_url
      }
    }
  }
`
