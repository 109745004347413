import { gql } from '@apollo/client'
import { Erc721Item } from '@appTypes/WalletTypes'

export interface BoxByIdItemsVars {
  tokenId: string
  chainId: number
}

export interface BoxByIdItemsData {
  erc721ItemsByBoxTokenId: Erc721Item[]
}

export const BOX_BY_ID_ITEMS_QUERY = gql`
  query GetBoxByIdItems($tokenId: String!, $chainId: Int!) {
    erc721ItemsByBoxTokenId(tokenId: $tokenId, chainId: $chainId) {
      address
      tokenId
      name
      nftsCount
      metadata {
        name
        image
        imageFull
        description
        animationType
        animation_url
      }
    }
  }
`
