import ace from '@assets/funnel//ace.svg'
import arrowRight from '@assets/funnel/arrow-right.svg'
import arrowUp from '@assets/funnel/arrowUp.svg'
import auction from '@assets/funnel/auction.svg'
import back from '@assets/funnel/back.svg'
import check from '@assets/funnel/check-transparent.svg'
import clock from '@assets/funnel/clock.svg'
import cross from '@assets/funnel/cross.svg'
import cube from '@assets/funnel/cube.svg'
import dollar from '@assets/funnel/dollarRaw.svg'
import fixedPrice from '@assets/funnel/fixedPrice.svg'
import fractions from '@assets/funnel/fractions.svg'
import gift from '@assets/funnel/gift.svg'
import lock from '@assets/funnel/lock.svg'
import pendingAction from '@assets/funnel/pending-action.svg'
import remove from '@assets/funnel/remove.svg'
import selected from '@assets/funnel/selected.svg'
import verified from '@assets/funnel/verified.svg'
import about from '@assets/icons/about.svg'
import blockIcon from '@assets/icons/card-icons/blockIcon.svg'
import loadingIcon from '@assets/icons/card-icons/loading.svg'
import placeBid from '@assets/icons/card-icons/placeBid.svg'
import poolEndIn from '@assets/icons/card-icons/poolEndIn.svg'
import poolLost from '@assets/icons/card-icons/poolLost.svg'
import poolProgress from '@assets/icons/card-icons/poolProgress.svg'
import copy from '@assets/icons/clip-blue.svg'
import close from '@assets/icons/close.svg'
import community from '@assets/icons/community.svg'
import docs from '@assets/icons/docs.svg'
import empty from '@assets/icons/emptyData.svg'
import external from '@assets/icons/external-link-blue.svg'
import feedback from '@assets/icons/feedback.svg'
import greeFlag from '@assets/icons/flag.svg'
import gear from '@assets/icons/gear.svg'
import hyperlinkDark from '@assets/icons/hyperlink-dark.svg'
import hyperlink from '@assets/icons/hyperlink.svg'
import members from '@assets/icons/icon-group-white.svg'
import instagram from '@assets/icons/instagram.svg'
import menuMobile from '@assets/icons/menuIcon.svg'
import binance_smart_chain from '@assets/icons/networks/bnb.svg'
import binance_testnet from '@assets/icons/networks/bsc-testnet.svg'
import error from '@assets/icons/networks/error.svg'
import ethereum from '@assets/icons/networks/ethereum.svg'
import polygon from '@assets/icons/networks/polygon.svg'
import ownership from '@assets/icons/ownership.svg'
import productMenuActive from '@assets/icons/product-menu-active.svg'
import productMenuHover from '@assets/icons/product-menu-hover.svg'
import productMenu from '@assets/icons/product-menu.svg'
import requestFeatures from '@assets/icons/request-features.svg'
import poolNftSold from '@assets/icons/rockpool/nft-sold.svg'
import poolSuccessful from '@assets/icons/rockpool/pool-completed.svg'
import allSaleTypes from '@assets/icons/sale-types/all.svg'
import box from '@assets/icons/sale-types/box.svg'
import buyNow from '@assets/icons/sale-types/buy-now.svg'
import collectivePurchase from '@assets/icons/sale-types/collective-purchase.svg'
import liveAuction from '@assets/icons/sale-types/live-auction.svg'
import recentlySold from '@assets/icons/sale-types/recently-sold.svg'
import startAuction from '@assets/icons/sale-types/start-auction.svg'
import search from '@assets/icons/search.svg'
import linkedin from '@assets/icons/social-network/linkedInIcon.svg'
import sort from '@assets/icons/sort.svg'
import telegram from '@assets/icons/telegram.svg'
import trophy from '@assets/icons/trophy.svg'
import twitter from '@assets/icons/twitter.svg'
import userPlus from '@assets/icons/userPlusWhite.svg'
import wallet from '@assets/icons/wallet-white.svg'
import warning from '@assets/icons/warning-icon.svg'
import { NetworksName } from '@config'
import Image from 'next/image'

export enum Icons {
  'sort' = 'sort',
  'empty' = 'empty',
  'copy' = 'copy',
  'external' = 'external',
  'twitter' = 'twitter',
  'instagram' = 'instagram',
  'linkedin' = 'linkedin',
  'telegram' = 'telegram',
  'hyperlink' = 'hyperlink',
  'hyperlinkDark' = 'hyperlinkDark',
  'search' = 'search',
  'close' = 'close',
  'gear' = 'gear',
  'about' = 'about',
  'docs' = 'docs',
  'community' = 'community',
  'requestFeatures' = 'requestFeatures',
  'feedback' = 'feedback',
  'menuMobile' = 'menuMobile',
  'members' = 'members',
  'warning' = 'warning',
  'loading' = 'loading',
  'ace' = 'ace',
  'cube' = 'cube',
  'selected' = 'selected',
  'remove' = 'remove',
  'cross' = 'cross',
  'back' = 'back',
  'fixedPrice' = 'fixedPrice',
  'auction' = 'auction',
  'dollar' = 'dollar',
  'clock' = 'clock',
  'arrowRight' = 'arrowRight',
  'basketRed' = 'basketRed',
  'check' = 'check',
  'pendingAction' = 'pendingAction',
  'arrowUp' = 'arrowUp',
  'lock' = 'lock',
  'greeFlag' = 'greeFlag',
  'trophy' = 'trophy',
  'verified' = 'verified',
  'gift' = 'gift',
  'fractions' = 'fractions',
  'wallet' = 'wallet',
  'productMenu' = 'productMenu',
  'productMenuHover' = 'productMenuHover',
  'productMenuActive' = 'productMenuActive',
  'userPlus' = 'userPlus'
}

export enum CardTypes {
  'placeBid' = 'placeBid',
  'poolProgress' = 'poolProgress',
  'poolEndIn' = 'poolEndIn',
  'poolLost' = 'poolLost',
  'poolSuccessful' = 'poolSuccessful',
  'poolNftSold' = 'poolNftSold',
  'ownership' = 'ownership',
  'blockIcon' = 'blockIcon',
  'loadingIcon' = 'loadingIcon'
}

export enum SaleTypesIcons {
  'all' = 'all',
  'box' = 'box',
  'buyNow' = 'buyNow',
  'collectivePurchase' = 'collectivePurchase',
  'rockpool' = 'rockpool',
  'liveAuction' = 'liveAuction',
  'recentlySold' = 'recentlySold',
  'startAuction' = 'startAuction',
  'fractionsSale' = 'fractionsSale'
}

export type IconEnum = Icons | NetworksName | SaleTypesIcons | CardTypes

export interface IconProps {
  name: Icons | NetworksName | SaleTypesIcons | CardTypes
  size: number
  sizeHeight?: number
  className?: string
}

export default function Icon({ name, size, sizeHeight, className }: IconProps) {
  const getIcon = (iconItem: IconEnum) => {
    switch (iconItem) {
      case NetworksName.ethereum:
        return ethereum
      case NetworksName.rinkeby:
        return ethereum
      case NetworksName.goerli:
        return ethereum
      case NetworksName.polygon:
        return polygon
      case NetworksName.mumbai:
        return polygon
      case NetworksName.binance_smart_chain:
        return binance_smart_chain
      case NetworksName.binance_testnet:
        return binance_testnet
      case SaleTypesIcons.all:
        return allSaleTypes
      case SaleTypesIcons.box:
        return box
      case SaleTypesIcons.buyNow:
        return buyNow
      case SaleTypesIcons.collectivePurchase:
        return collectivePurchase
      case SaleTypesIcons.rockpool:
        return poolProgress
      case SaleTypesIcons.liveAuction:
        return liveAuction
      case SaleTypesIcons.recentlySold:
        return recentlySold
      case SaleTypesIcons.startAuction:
        return startAuction
      case CardTypes.placeBid:
        return placeBid
      case CardTypes.blockIcon:
        return blockIcon
      case CardTypes.poolProgress:
        return poolProgress
      case CardTypes.poolEndIn:
        return poolEndIn
      case CardTypes.poolLost:
        return poolLost
      case CardTypes.poolSuccessful:
        return poolSuccessful
      case CardTypes.poolNftSold:
        return poolNftSold
      case CardTypes.loadingIcon:
        return loadingIcon
      case Icons.warning:
        return warning
      case Icons.sort:
        return sort
      case Icons.search:
        return search
      case Icons.empty:
        return empty
      case Icons.close:
        return close
      case Icons.gear:
        return gear
      case Icons.about:
        return about
      case Icons.docs:
        return docs
      case Icons.community:
        return community
      case Icons.requestFeatures:
        return requestFeatures
      case Icons.feedback:
        return feedback
      case Icons.menuMobile:
        return menuMobile
      case Icons.members:
        return members
      case Icons.copy:
        return copy
      case Icons.external:
        return external
      case Icons.twitter:
        return twitter
      case Icons.instagram:
        return instagram
      case Icons.linkedin:
        return linkedin
      case Icons.telegram:
        return telegram
      case Icons.hyperlink:
        return hyperlink
      case Icons.hyperlinkDark:
        return hyperlinkDark
      case CardTypes.ownership:
        return ownership
      case Icons.ace:
        return ace
      case Icons.cube:
        return cube
      case Icons.selected:
        return selected
      case Icons.remove:
        return remove
      case Icons.cross:
        return cross
      case Icons.back:
        return back
      case Icons.fixedPrice:
        return fixedPrice
      case Icons.auction:
        return auction
      case Icons.dollar:
        return dollar
      case Icons.clock:
        return clock
      case Icons.arrowRight:
        return arrowRight
      case Icons.check:
        return check
      case Icons.pendingAction:
        return pendingAction
      case Icons.arrowUp:
        return arrowUp
      case Icons.lock:
        return lock
      case Icons.greeFlag:
        return greeFlag
      case Icons.trophy:
        return trophy
      case Icons.verified:
        return verified
      case Icons.gift:
        return gift
      case Icons.fractions:
        return fractions
      case Icons.wallet:
        return wallet
      case Icons.productMenu:
        return productMenu
      case Icons.productMenuHover:
        return productMenuHover
      case Icons.productMenuActive:
        return productMenuActive
      case Icons.userPlus:
        return userPlus
      default:
        return error
    }
  }
  const icon = getIcon(name)

  return <Image src={icon} alt={icon} className={className} width={size} height={sizeHeight ?? size} />
}
