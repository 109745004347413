import { ProductMenu } from '@components/design-system/header/HeaderProductMenu'
import AccountButton from '@components/shared/buttons/AccountButton'
import Icon, { Icons } from '@components/shared/Icon'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'

interface HeaderMobileProps {
  logo: {
    image: string
    name: string
    href: string
  }
  menu: { name: string; href: string; activeHref: string[]; activeParams?: string[] }[]
  productMenu: ProductMenu[]
}

function HeaderMobile({ productMenu, menu, logo }: HeaderMobileProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isProductMenuOpen, setIsProductMenuOpen] = useState<boolean>(false)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false)
  const router = useRouter()

  const handleMenuOpen = () => {
    setIsProductMenuOpen(false)
    setIsProfileMenuOpen(false)
    setIsMenuOpen(prev => !prev)
  }

  const handleProductMenuOpen = () => {
    setIsMenuOpen(false)
    setIsProductMenuOpen(prev => !prev)
  }

  const isNavItemActive = (activeHref: string[], activeParams?: string[]) => {
    return (
      (activeHref.some(h => router.route === h) && !activeParams) ||
      (activeHref.some(h => router.route === h) && !!activeParams && router.asPath.includes(activeParams[0]))
    )
  }

  const renderFloatMenu = () => {
    return (
      <S.NavbarContainer>
        <Link href={logo.href} passHref>
          <S.Logo>
            <S.LogoImage src={logo.image} alt='Nftfy' />
            <div>|</div>
            <div>{`${logo.name}`}</div>
          </S.Logo>
        </Link>
        <S.ActionsWrapper>
          <S.OpenMenuButton invert onClick={handleMenuOpen}>
            {!isMenuOpen ? <Icon size={18} name={Icons.menuMobile} /> : <Icon size={16} name={Icons.close} />}
          </S.OpenMenuButton>
          <S.OpenMenuButton invert={isProductMenuOpen} onClick={handleProductMenuOpen}>
            {!isProductMenuOpen ? <Icon size={16} name={Icons.productMenu} /> : <Icon size={16} name={Icons.close} />}
          </S.OpenMenuButton>
          <S.OpenMenuButton>
            <AccountButton mobileButton />
          </S.OpenMenuButton>
        </S.ActionsWrapper>
      </S.NavbarContainer>
    )
  }

  return (
    <S.Wrapper>
      {renderFloatMenu()}
      <S.OpenedMenu visible={isMenuOpen || isProductMenuOpen || isProfileMenuOpen}>
        <S.OpenedTopMenu>{renderFloatMenu()}</S.OpenedTopMenu>
        <S.OpenedMenuContent>
          {isMenuOpen && (
            <S.Nav>
              {menu.map(({ name, href, activeHref, activeParams }) => (
                <Link href={href} passHref key={name}>
                  <S.NavItem active={isNavItemActive(activeHref, activeParams)}>{name}</S.NavItem>
                </Link>
              ))}
            </S.Nav>
          )}
          {isProductMenuOpen && (
            <S.Nav>
              {productMenu.map(({ name, href, activeHref, enabled }) =>
                enabled ? (
                  <Link href={href} passHref key={name}>
                    <S.NavItem active={isNavItemActive(activeHref)}>{name}</S.NavItem>
                  </Link>
                ) : (
                  <S.NavItem disabled={!enabled} active={isNavItemActive(activeHref)}>
                    {name}
                  </S.NavItem>
                )
              )}
            </S.Nav>
          )}
        </S.OpenedMenuContent>
      </S.OpenedMenu>
    </S.Wrapper>
  )
}

const S = {
  Wrapper: styled.header`
    background: transparent;
    padding: ${({ theme }) => theme.margin.small} 0 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 9;
    top: 0;
    height: 88px;
  `,
  NavbarContainer: styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  `,
  Logo: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.margin.xs};

    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.gray[2]};
    font-size: 16px;
  `,
  LogoImage: styled.img`
    display: block;
    height: 32px;
    width: 100%;
  `,

  ActionsWrapper: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.margin.xs};
  `,

  OpenMenuButton: styled.div<{ invert?: boolean }>`
    border: 1px solid ${props => props.theme.colors.gray[0]};
    display: flex;
    border-radius: 50px;
    justify-content: center;
    width: 32px;
    height: 32px;

    ${({ invert }) =>
      invert &&
      `
        img {
            filter: invert(1);
          }
      
          &.light {
        span {
          filter: invert(1);
        }
      }
    `}
  `,
  MenuItem: styled.div`
    margin-left: 8px;
  `,

  OpenedMenu: styled.div<{ visible?: boolean }>`
    background: ${({ theme }) => theme.colors.white};
    position: absolute;
    width: 100%;
    height: ${({ visible }) => (visible ? '100vh' : '1px !important')};
    visibility: ${({ visible }) => (visible ? 'visible' : 'invisible')};
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    top: 0;
    left: 0;
    z-index: 9;
    padding: calc(${({ theme }) => theme.margin.small} + 3px) ${({ theme }) => theme.margin.small} ${({ theme }) => theme.margin.small};
    transition: all 0.2s ease-in-out;
  `,
  OpenedTopMenu: styled.div`
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  `,

  OpenedMenuContent: styled.div`
    margin-top: 16px;
  `,

  Nav: styled.nav`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 36px;
    align-items: center;
  `,

  OpenedSearch: styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
    background: ${({ theme }) => theme.colors.gray[5]};
    padding: ${({ theme }) => theme.margin.large};
  `,

  OpenSearchButton: styled.div`
    border: 1px solid ${props => props.theme.colors.gray[0]};
    display: flex;
    border-radius: 50px;
    justify-content: center;
    width: 36px;
    height: 100%;
    img {
      filter: invert(0.8);
    }

    &.light {
      span {
        filter: invert(1);
      }
    }
  `,
  OpenedSearchHeader: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  SearchInputContent: styled.div`
    width: 80%;
  `,
  CloseButton: styled.div`
    border: 1px solid ${props => props.theme.colors.gray[0]};
    display: flex;
    border-radius: 50px;
    justify-content: center;
    width: 36px;
    height: 36px;
    img {
      filter: invert(0.8);
    }
  `,
  OpenedSearchContent: styled.div`
    margin-top: 32px;
  `,
  NavItem: styled.a<{ active?: boolean; disabled?: boolean }>`
    color: ${({ theme }) => theme.colors.black};
    font-size: 16px;
    line-height: 32px;

    ${({ disabled, theme }) =>
      disabled &&
      `
       color: ${theme.colors.gray[1]} !important;
       cursor: not-allowed;  
    `}

    ${({ theme, active }) =>
      active &&
      `
      color: ${theme.colors.primary.main};  
    `}
  `
}

export default HeaderMobile
