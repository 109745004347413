import Image from 'next/image'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getImageProfileDefault, refreshProfile } from '../../services/UtilService'

interface ProfileAvatarProps {
  avatarUrl?: string
  width: number
  height: number
  account: string
  circle?: boolean
  className?: string
  onMouseLeave?: () => void
  onMouseOver?: () => void
  action?: () => void
}

const ProfileAvatar = ({ avatarUrl, width, height, account, circle, className, onMouseLeave, onMouseOver, action }: ProfileAvatarProps) => {
  const [avatar, setAvatar] = useState(avatarUrl || getImageProfileDefault(account.toLowerCase()))

  const handleError = () => {
    refreshProfile(account || '', 'avatar')
    setAvatar(getImageProfileDefault(account.toLowerCase()))
  }

  useEffect(() => {
    if (avatarUrl) {
      setAvatar(avatarUrl)
    }
  }, [avatarUrl])
  return (
    <S.Container
      circle={circle}
      width={width}
      height={height}
      className={className}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={action}
    >
      <S.Image src={avatar} width={width} height={height} layout='fixed' onError={() => handleError()} unoptimized />
    </S.Container>
  )
}
const S = {
  Container: styled.div<{ circle?: boolean; width: number; height: number }>`
    display: flex;
    overflow: hidden;
    width: ${({ width }) => `${width}px`} !important;
    height: ${({ height }) => `${height}px`} !important;
    ${({ circle }) => (circle ? 'border-radius: 50%;' : 'border-radius: 8px;')}
  `,
  Image: styled(Image)`
    object-fit: cover;
  `
}
export default ProfileAvatar
