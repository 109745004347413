import { Button } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import attention from '@assets/icons/attention.svg'
import Image from 'next/image'

export const WalletErrorAlert: React.FC = () => {
  const [visible, setVisible] = useState(true)
  return (
    <S.Alert className={`${visible ? '' : 'disabled'}`}>
      <S.Container>
        <S.Message>
          <Image src={attention.src} alt='Attention' width={32} height={32} layout='fixed' />
          <span>For some unspecified reason you wallet could not be loaded, you can request a wallet error review</span>
        </S.Message>
        <S.Actions>
          <a href='https://docs.google.com/forms/d/1-Iwv8ZFKekgLMfudiqvX0JHjV4RlN52UADbT3k0EyRA' target='_blank' rel='noreferrer'>
            <S.ActionButton>Report</S.ActionButton>
          </a>
          <S.ActionButton onClick={() => setVisible(false)}>Close</S.ActionButton>
        </S.Actions>
      </S.Container>
    </S.Alert>
  )
}

const S = {
  Alert: styled.div`
    flex: 1;
    width: 100%;
    min-height: 80px;
    background: ${props => props.theme.colors.red.main};

    font-size: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.white};
    font-size: 16px;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      display: none;
    }
  `,
  Container: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${props => props.theme.viewport.desktopXl};
    height: 100%;
    @media (max-width: ${props => props.theme.viewport.desktop}) {
      padding: 32px 24px;
    }

    @media (max-width: ${props => props.theme.viewport.tablet}) {
      padding: 32px 8px;
      flex-direction: column;
    }
  `,
  Message: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 22px;
      height: 20px;
    }
    span {
      margin-left: 8px;
      line-height: 24px;
    }
    @media (max-width: ${props => props.theme.viewport.tablet}) {
      margin-bottom: 16px;
    }
  `,
  Actions: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  `,
  ActionButton: styled(Button)`
    width: 127px;
    height: 37px;

    border: 1px solid ${props => props.theme.colors.white};
    box-sizing: border-box;
    border-radius: 8px;

    background: ${props => props.theme.colors.red.main};
    color: ${props => props.theme.colors.white};

    font-size: 16px;
    font-weight: 400;

    :nth-child(1) {
      margin-right: 8px;
    }

    &:hover {
      border: 1px solid ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.red.main};
      color: ${props => props.theme.colors.white};
    }
    &:focus {
      border: 1px solid ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.red.main};
      color: ${props => props.theme.colors.white};
    }

    @media (max-width: ${props => props.theme.viewport.tablet}) {
      font-size: 1.2rem;
    }
  `
}
