import HeaderDesktop from '@components/design-system/header/HeaderDesktop'
import HeaderMobile from '@components/design-system/header/HeaderMobile'
import useBreakpoints from '@hooks/useBreakpoints'
import { ProductMenu } from './HeaderProductMenu'

interface HeaderProps {
  logo: {
    image: string
    name: string
    href: string
  }
  menu: { name: string; href: string; activeHref: string[] }[]
  productMenu: ProductMenu[]
}

export default function Header({ logo, menu, productMenu }: HeaderProps) {
  const { isMobile } = useBreakpoints()

  return isMobile ? (
    <HeaderMobile logo={logo} menu={menu} productMenu={productMenu} />
  ) : (
    <HeaderDesktop logo={logo} menu={menu} productMenu={productMenu} />
  )
}
