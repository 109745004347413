import { gql } from '@apollo/client'

export interface NftCollection {
  chainId: number
  creator?: string
  discord?: string
  image_url?: string
  slug?: string
  verified: boolean
  collectionName: string
  collectionAddress: string
}

export interface NftCollectionData {
  erc721Collection: NftCollection
}

export interface NftCollectionVar {
  chainId: number
  collectionAddress: string
}

export const NFT_COLLECTION = gql`
  query Erc721Collection($chainId: Int!, $collectionAddress: String!) {
    erc721Collection(chainId: $chainId, collectionAddress: $collectionAddress) {
      chainId
      creator
      discord
      image_url
      slug
      verified
      collectionName
      collectionAddress
    }
  }
`
