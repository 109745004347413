import erc20Abi from '@abi/erc20.json'
import erc721Abi from '@abi/erc721.json'
import nftfyAbi from '@abi/nftfy.json'
import { AssetERC20 } from '@appTypes/WalletTypes'
import { chainConfig, globalConfig } from '@config'
import { MaxUint256 } from '@ethersproject/constants'
import { clearTransaction, handleTransaction, TransactionType } from '@graphql/variables/TransactionVariable'
import { initializeWeb3 } from '@services/MultiWalletService'
import { notifyError } from '@services/NotificationService'
import { coins, estimateGasPrice, scale, units } from '@services/UtilService'
import { getErc20Balance } from '@services/WalletService'
import BigNumber from 'bignumber.js'
import { AbiItem } from 'web3-utils'
import erc20SharesAbi from '../abi/erc20shares.json'
import { accountVar } from '../graphql/variables/WalletVariable'
import { code } from '../messages'

export const isApprovedForAllErc721 = async (erc721Address: string, ownerAddress: string, operatorAddress: string, chainId: number) => {
  const web3 = initializeWeb3(chainId, true)
  const contractErc721 = new web3.eth.Contract(erc721Abi as AbiItem[], erc721Address)
  const approved = await contractErc721.methods.isApprovedForAll(ownerAddress, operatorAddress).call()
  return approved
}

export const setApprovalForAllErc721 = async (erc721Address: string, ownerAddress: string, operatorAddress: string, chainId: number) => {
  const web3 = initializeWeb3(chainId, false)
  const contractErc721 = new web3.eth.Contract(erc721Abi as AbiItem[], erc721Address)
  await contractErc721.methods
    .setApprovalForAll(operatorAddress, true)
    .send({ from: ownerAddress, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
      tx ? handleTransaction(tx, TransactionType.setApprovalForAllErc721) : clearTransaction()
    })
}
export const getFractionalizeAssetsList = async (account: string, chainId: number) => {
  const eth = globalConfig.balancerEthAddress
  const config = chainConfig(chainId)
  if (!config || !config.networkTokenAddress || !config.erc20List) {
    return []
  }
  if (!account) {
    return config.erc20List
  }
  const erc20Promises: Promise<AssetERC20>[] = config.erc20List.map(async erc20 => {
    const balance = await getErc20Balance(
      account,
      erc20.address === eth ? config.networkTokenAddress : erc20.address,
      erc20.decimals,
      chainId
    )
    return { ...erc20, balance: balance.toString() }
  })
  return Promise.all(erc20Promises)
}
export const isApprovedErc20 = async (erc20Address: string, account: string, chainId: number) => {
  const config = chainConfig(chainId)
  const nftfyTokenAddress = config?.nftfyToken.address
  const web3 = initializeWeb3(chainId, true)
  const contractERC20 = new web3.eth.Contract(erc20Abi as AbiItem[], nftfyTokenAddress)
  return contractERC20.methods.allowance(account, erc20Address).call()
}
export const approveErc20Bridge = async (
  erc20Address: string,
  erc20Decimals: number,
  erc20Amount: string,
  chainId: number,
  spenderAddress?: string
) => {
  const web3 = initializeWeb3(chainId, false)
  const contractErc20 = new web3.eth.Contract(erc20Abi as AbiItem[], erc20Address)
  await contractErc20.methods.approve(spenderAddress || accountVar(), erc20Amount).send({ from: accountVar() })
}
export const getApproved721 = async (erc721Address: string, erc721TokenId: string, chainId: number) => {
  const config = chainConfig(chainId)
  const web3 = initializeWeb3(chainId, true)

  const { contractFixedPrice } = config.fracionalizerV1_2

  const contractErc721 = new web3.eth.Contract(erc721Abi as AbiItem[], erc721Address)
  const address = await contractErc721.methods.getApproved(erc721TokenId).call()
  return address === contractFixedPrice
}

export const approveErc721 = async (erc721Address: string, erc721TokenId: string, account: string, chainId: number) => {
  const config = chainConfig(chainId)
  const nftfyAddress = config.fracionalizerV1_2.contractFixedPrice
  const web3 = initializeWeb3(chainId, false)
  const contractErc721 = new web3.eth.Contract(erc721Abi as AbiItem[], erc721Address)
  contractErc721.methods
    .approve(nftfyAddress, erc721TokenId)
    .send({ from: account, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
      tx ? handleTransaction(tx, TransactionType.fractionalizeApprove) : clearTransaction()
    })
}
export const fractionalizeErc721 = async (
  erc721Address: string,
  erc721Id: string,
  name: string,
  symbol: string,
  fractionPrice: string,
  fractionCount: string,
  fractionDecimals: number,
  paymentTokenAddress: string,
  account: string,
  chainId: number
) => {
  const config = chainConfig(chainId)
  if (!config || !config.fracionalizerV1_2 || !config.fracionalizerV1_2.contractFixedPrice) {
    notifyError(code[5011], 'Invalid chain id')
    return
  }
  const nftfyAddress = config?.fracionalizerV1_2.contractFixedPrice
  const ethAddress = config?.networkTokenAddress
  const { balancerEthAddress } = globalConfig
  const web3 = initializeWeb3(chainId, false)
  const contractNftfy = new web3.eth.Contract(nftfyAbi as AbiItem[], nftfyAddress)
  contractNftfy.methods
    .fractionalize(
      erc721Address,
      erc721Id,
      name,
      symbol,
      fractionDecimals,
      units(fractionCount, fractionDecimals),
      fractionPrice,
      paymentTokenAddress === balancerEthAddress ? ethAddress : paymentTokenAddress
    )
    .send({ from: account, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
      tx ? handleTransaction(tx, TransactionType.fractionalize) : clearTransaction()
    })
}

export const redeemErc20 = async (erc20Address: string, account: string, chainId: number) => {
  const config = chainConfig(chainId)
  const ethAddress = config?.networkTokenAddress

  const web3 = initializeWeb3(chainId, false)
  const contractErc20Shares = new web3.eth.Contract(erc20SharesAbi as AbiItem[], erc20Address)

  const paymentToken = await contractErc20Shares.methods.paymentToken().call()
  const redeemAmount = await getAccountRedeemAmount(erc20Address, account, chainId)

  if (paymentToken === ethAddress) {
    contractErc20Shares.methods
      .redeem()
      .send({ from: account, value: units(redeemAmount, 18), gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
        tx ? handleTransaction(tx, TransactionType.redeem) : clearTransaction()
      })
  } else {
    contractErc20Shares.methods.redeem().send({ from: account, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
      tx ? handleTransaction(tx, TransactionType.redeem) : clearTransaction()
    })
  }

  return true
}

export const getAccountRedeemAmount = async (erc20Address: string, account: string, chainId: number) => {
  const web3 = initializeWeb3(chainId, true)
  const contractErc20Shares = new web3.eth.Contract(erc20SharesAbi as AbiItem[], erc20Address)
  const redeemAmountOf = await contractErc20Shares.methods.redeemAmountOf(account).call()
  return coins(redeemAmountOf, 18)
}

export const isApprovedErc20Redeem = async (erc20ShareAddress: string, erc20PaymentAddress: string, account: string, chainId: number) => {
  const web3 = initializeWeb3(chainId, true)
  const contractERC20 = new web3.eth.Contract(erc20Abi as AbiItem[], erc20PaymentAddress)
  return contractERC20.methods.allowance(account, erc20ShareAddress).call()
}

export const approveErc20Redeem = async (erc20ShareAddress: string, erc20PaymentAddress: string, account: string, chainId: number) => {
  const web3 = initializeWeb3(chainId, false)
  const contractErc20 = new web3.eth.Contract(erc20Abi as AbiItem[], erc20PaymentAddress)
  contractErc20.methods
    .approve(erc20ShareAddress, MaxUint256.toString())
    .send({ from: account, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
      tx ? handleTransaction(tx, TransactionType.approveErc20Redeem) : clearTransaction()
    })
}

export const claimErc20 = async (erc20Address: string, account: string, chainId: number) => {
  const web3 = initializeWeb3(chainId, false)
  const contractErc20Shares = new web3.eth.Contract(erc20SharesAbi as AbiItem[], erc20Address)
  contractErc20Shares.methods.claim().send({ from: account, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
    tx ? handleTransaction(tx, TransactionType.portfolioClaim) : clearTransaction()
  })

  return true
}

export const getAccountClaimAmount = async (erc20Address: string, account: string, chainId: number) => {
  const config = chainConfig(chainId)
  const ethAddress = config?.networkTokenAddress

  const web3 = initializeWeb3(chainId, true)
  const contractErc20Shares = new web3.eth.Contract(erc20SharesAbi as AbiItem[], erc20Address)

  let decimals = 18
  const paymentToken = await contractErc20Shares.methods.paymentToken().call()

  if (paymentToken !== ethAddress) {
    const contractPayment = new web3.eth.Contract(erc20Abi as AbiItem[], paymentToken)
    decimals = Number(await contractPayment.methods.decimals().call())
  }

  const vaultBalance = await contractErc20Shares.methods.vaultBalanceOf(account).call()

  return scale(new BigNumber(vaultBalance), -decimals)
}
