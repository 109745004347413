import { useReactiveVar } from '@apollo/client'
import clipBlue from '@assets/icons/clip-blue.svg'
import iconNftfy from '@assets/icons/coins/nftfy.svg'
import boxIcon from '@assets/icons/header/box.svg'
import linkIcon from '@assets/link-icon-white.svg'
import { Dropdown, Menu, Tooltip } from 'antd'
import { useRouter } from 'next/router'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'
import { chainConfig } from '../../../config'
import { themeVar } from '../../../graphql/variables/SharedVariable'
import { chainIdVar, connectWalletModalVar } from '../../../graphql/variables/WalletVariable'
import { useAccount } from '../../../hooks/AccountHooks'
import { useDollarQuotation } from '../../../hooks/QuoteHooks'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useErc20Balance } from '../../../hooks/WalletHooks'
import { notifySuccess } from '../../../services/NotificationService'
import {
  formatShortAddressDescriptionNft,
  formatShortAddressWallet,
  formatToLocaleString,
  toHumanFormat
} from '../../../services/UtilService'
import Icon, { Icons as AppIcons } from '../Icon'
import ProfileAvatar from '../ProfileAvatar'

interface AccountButtonProps {
  mobileButton?: boolean
}

export default function AccountButton({ mobileButton }: AccountButtonProps) {
  const chainId = useReactiveVar(chainIdVar)
  const theme = useReactiveVar(themeVar)
  const { isTablet } = useBreakpoints()
  const config = chainConfig(chainId)
  const router = useRouter()
  const { account, name, image } = useAccount()

  const { balance } = useErc20Balance(account || '', config.networkTokenAddress, config.networkTokenDecimals, chainId)
  const balanceNftfy = useErc20Balance(account || '', config.nftfyToken.address, config.nftfyToken.decimals, chainId)
  const { priceDollar } = useDollarQuotation(balance, chainId, config.networkTokenAddress, config.networkTokenDecimals)
  const priceDollarNftfy = useDollarQuotation(balanceNftfy.balance, chainId, config.nftfyToken.address, config.nftfyToken.decimals)

  const handleClick = () => {
    if (account) {
      router.push(`/profile/${chainId}/${account}?tokenType=collection`)
    }
  }

  const handleRedirectFunds = () => {
    window.open('https://app.uniswap.org/#/swap?chain=mainnet', '_blank')
  }

  const copyAddress = () => {
    notifySuccess('Address copied!')
  }

  const menu = (
    <ContentDrop>
      <ContentAddress>
        <ProfileAvatar avatarUrl={image} width={28} height={28} account={account || ''} circle />
        <span>{formatShortAddressDescriptionNft(name || account || '')}</span>
        <CopyToClipboard onCopy={copyAddress} text={account || ''}>
          <Tooltip placement='bottomRight' title='Click to copy address'>
            <div>
              <Icons src={clipBlue.src} alt='dollar icon' width={16} height={16} />
            </div>
          </Tooltip>
        </CopyToClipboard>
      </ContentAddress>
      <ListItem>
        <ListLink onClick={handleClick}>
          <Icons src={boxIcon.src} alt='profileIcon' width={20} height={20} />
          <span>My Profile</span>
        </ListLink>
      </ListItem>
      <ContentFunds>
        <Icon name={config.name} size={28} />
        <div>
          <span>{`${formatToLocaleString(balance, 2)} ${config.networkTokenSymbol}`}</span>
          <small>{`$${priceDollar}`}</small>
        </div>
      </ContentFunds>
      <ContentFunds>
        <Icons src={iconNftfy.src} width={28} height={28} />
        <div>
          <span>{balanceNftfy.balance === '0' ? '0' : toHumanFormat(Number(balanceNftfy.balance))} NFTFY</span>
          <small>{`$${priceDollarNftfy.priceDollar}`}</small>
        </div>
        <div>
          <ButtonFunds onClick={handleRedirectFunds} className={theme}>
            <Icons src={linkIcon.src} alt='dollar icon' width={13} height={13} />
            Add funds
          </ButtonFunds>
        </div>
      </ContentFunds>
    </ContentDrop>
  )

  if (mobileButton) {
    return account ? (
      <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
        <WalletButton>
          <Icon size={24} name={AppIcons.wallet} />
        </WalletButton>
      </Dropdown>
    ) : (
      <WalletButton type='button' onClick={() => connectWalletModalVar(true)}>
        <Icon size={24} name={AppIcons.wallet} />
      </WalletButton>
    )
  }

  return (
    <>
      {account && (
        <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
          <Button type='button'>
            <span>{isTablet ? formatShortAddressWallet(name || account, 5, true) : formatShortAddressDescriptionNft(name || account)}</span>
            {!image && <Icon name={AppIcons.wallet} size={24} />}
            {image && <ImageProfile src={image} width={24} height={24} />}
          </Button>
        </Dropdown>
      )}
      {!account && (
        <Button type='button' onClick={() => connectWalletModalVar(true)}>
          <span>Connect Wallet</span>
        </Button>
      )}
    </>
  )
}

const { Button, ImageProfile, Icons, ContentDrop, ContentAddress, ContentFunds, ListItem, ButtonFunds, ListLink, WalletButton } = {
  ContentDrop: styled(Menu)`
    width: 345px;
    padding: 20px 10px;
    border-radius: 16px;
    background: ${props => props.theme.colors.gray[0]};
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.8rem;
    margin-top: 10px;
  `,
  ContentAddress: styled.div`
    display: flex;
    gap: 1.6rem;
    align-items: center;
    padding: 0.8rem;
    > span {
      font-weight: 300;
      font-size: 16px;
      line-height: 14px;
      color: ${props => props.theme.colors.black};
      align-items: center;
    }

    img {
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.5;
      }
    }
  `,
  ContentFunds: styled.div`
    width: 100%;
    height: 62px;
    background: ${props => props.theme.colors.white};
    border-radius: 16px;
    padding: 12px;
    display: grid;
    grid-template-columns: 28px 1fr 1fr;
    align-items: center;
    gap: 12px;
    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span {
        font-weight: 300;
        font-size: 16px;
        line-height: 14px;
        color: ${props => props.theme.colors.black};
      }
      > small {
        font-weight: 400;
        font-size: 14px;
        color: ${props => props.theme.colors.black};
      }
    }
  `,
  ListItem: styled.div`
    padding: 12px;
  `,
  ButtonFunds: styled.button`
    background: transparent;
    border-radius: 32px;
    box-shadow: none;
    border: none;
    border: 1px solid ${props => props.theme.colors.blue.main};
    padding: 0 16px;
    height: 36px;
    color: ${props => props.theme.colors.black};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &.light {
      img {
        filter: invert(1);
      }
    }

    &:hover {
      background: ${props => props.theme.colors.blue.dark};
    }
  `,
  WalletButton: styled.button`
    width: 32px;
    height: 32px;
    transition: all 0.1s ease-in-out;
    border-radius: 32px;
    color: ${props => props.theme.colors.constants.white};
    background: ${props => props.theme.colors.blue.main};
    border: none;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Button: styled.button`
    background: transparent;
    border: none;
    font-size: 16px;
    line-height: 24px;
    height: 36px;
    padding: 0 16px;
    border-radius: 32px;
    color: ${props => props.theme.colors.constants.white};
    background: ${props => props.theme.colors.blue.main};
    transition: all 0.1s ease-in-out;

    display: flex;
    gap: 8px;

    align-items: center;

    cursor: pointer;

    &:hover {
      background: ${props => props.theme.colors.blue.dark};
    }

    &.active {
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.black};
    }
  `,
  ImageProfile: styled.img`
    border-radius: 100%;
  `,
  Icons: styled.img`
    cursor: pointer;
  `,
  ListLink: styled.a`
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
    > span {
      font-weight: 300;
      font-size: 16px;
      line-height: 14px;
      color: ${props => props.theme.colors.black};
    }

    &:hover {
      opacity: 0.5;
    }
  `
}
