import { gql } from '@apollo/client'
import { Collection } from '@appTypes/CollectionTypes'

export interface CollectionsBySlugVars {
  chainId: number
  slug?: string
}

export interface CollectionsBySlugData {
  erc721CollectionBySlug: Collection
}

export const GET_COLLECTIONS_BY_SLUG = gql`
  query Erc721CollectionBySlug($chainId: Int!, $slug: String!) {
    erc721CollectionBySlug(chainId: $chainId, slug: $slug) {
      banner_url
      discord
      twitter
      telegram
      instagram
      website
      image_url
      slug
      description
      creator
      collectionName
      collectionAddress
      chainId
      verified
      stats {
        itemsCount
        ownersCount
        floorPrice
        volumeTraded
      }
    }
  }
`
