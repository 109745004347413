import { gql } from '@apollo/client'

export interface BoxByIdItemsCountVars {
  tokenId: string
  chainId: number
}

export interface BoxByIdItemsCountData {
  boxItemsCount: number
}

export const BOX_BY_ID_ITEMS_COUNT_QUERY = gql`
  query GetBoxByIdItems($tokenId: ID!, $chainId: Int!) {
    boxItemsCount(tokenId: $tokenId, chainId: $chainId)
  }
`
