import { useMutation } from '@apollo/client'
import messageIcon from '@assets/icons/footer/message.svg'
import useBreakpoints from '@hooks/useBreakpoints'
import { Input } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { backendV2 } from '../../../../graphql/ClientGraphql'
import { NEWSLETTER, NewsletterData, NewsletterVars } from '../../../../graphql/nftfy/backend/shared/footer/NewsletterMutation'
import { regexValidateEmail } from '../../../../services/UtilService'
import { DefaultButton } from '../../buttons/DefaultButton'
import { FooterMenuSocial } from './FooterMenuSocial'

export interface FooterProps {
  className?: string
}

export const Footer: React.FC<FooterProps> = ({ className }: FooterProps) => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [hasError, setHasError] = useState(false)

  const { isMobile } = useBreakpoints()

  const handleSuccess = (data: NewsletterData) => {
    if (data.contact.hasError) {
      setMessage(data.contact.message)
      setHasError(data.contact.hasError)
      return
    }
    setMessage('Your email has been successfully registered')
    setHasError(false)
    setEmail('')
  }

  const handleErrorMutation = () => {
    throw new Error('Mutation: Newsletter')
  }

  const [createNewsletter, { loading }] = useMutation<NewsletterData, NewsletterVars>(NEWSLETTER, {
    client: backendV2,
    onCompleted: handleSuccess,
    onError: handleErrorMutation
  })
  const handleSendNewsletter = () => {
    if (email && regexValidateEmail(email)) {
      createNewsletter({ variables: { email } })
      return
    }
    setMessage('The e-mail you entered is not valid.')
    setHasError(true)
  }

  return (
    <S.Container className={className}>
      <S.ContentNav>
        <div>
          <S.Nav className='newsletter'>
            <h2>Newsletter</h2>
            <S.InputBox hasError={hasError}>
              <Image src={messageIcon.src} alt='message icon' width={34} height={16} layout='fixed' unoptimized />
              <Input
                type='email'
                placeholder='Your e-mail'
                onBlur={() => {
                  setHasError(false)
                  setMessage('')
                }}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <S.DefaultButton
                htmlType='submit'
                label='Subscribe'
                color='primary'
                size='default'
                boldText
                loading={loading}
                width={isMobile ? 100 : 130}
                height={40}
                onClick={() => handleSendNewsletter()}
              />
            </S.InputBox>
            <S.Message hasError={hasError}>{message}</S.Message>
          </S.Nav>
          <S.Nav className='token'>
            <h2>$NFTFY Token</h2>
            <nav>
              <ul>
                <li>
                  <Link href='/token/transparency'>
                    <a>Transparency</a>
                  </Link>
                </li>
                <li>
                  <Link href='/token/bridge'>
                    <a>Bridge</a>
                  </Link>
                </li>
                <li>
                  <Link href='/token/claim'>
                    <a>Claim</a>
                  </Link>
                </li>
                <li>
                  <Link href='/token/farm'>
                    <a>Farm</a>
                  </Link>
                </li>
              </ul>
            </nav>
          </S.Nav>
          <S.Nav className='about'>
            <h2>About us</h2>
            <nav>
              <ul>
                <li>
                  <a target='_blank' href='https://docs.nftfy.org/' rel='noreferrer'>
                    What is NFTFY{' '}
                  </a>
                </li>
                <li>
                  <a target='_blank' href='https://medium.com/nftfy' rel='noreferrer'>
                    Medium Articles{' '}
                  </a>
                </li>
              </ul>
            </nav>
          </S.Nav>
          <S.Nav className='community'>
            <h2>Community</h2>
            <nav>
              <ul>
                <li>
                  <a target='_blank' href='https://bit.ly/3xdGR4k' rel='noreferrer'>
                    Discord
                  </a>
                </li>
                <li>
                  <a target='_blank' href='https://forms.gle/sbavLNFfgbv1QG418' rel='noreferrer'>
                    Request Feature
                  </a>
                </li>
                <li>
                  <a target='_blank' href='https://forms.gle/pR3HUtXSC5fn4coQA' rel='noreferrer'>
                    Feedback
                  </a>
                </li>
              </ul>
            </nav>
          </S.Nav>
          <S.Nav className='social'>
            <h2>Social</h2>
            <FooterMenuSocial />
          </S.Nav>
        </div>
      </S.ContentNav>
      <S.ContentCopy>
        <span>{`© Nftfy 2020 - ${new Date().getFullYear()} - All rights reserved.`} </span>
      </S.ContentCopy>
    </S.Container>
  )
}

const S = {
  Container: styled.footer`
    width: 100%;
    max-width: 1480px;
    justify-self: center;
    align-self: end;
    height: 280px;
    display: flex;
    flex-direction: column;
    @media (max-width: ${props => props.theme.viewport.desktop}) {
      height: auto;
      padding: 20px;
    }
  `,
  ContentNav: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      gap: 48px;
      @media (max-width: ${props => props.theme.viewport.desktop}) {
        .newsletter {
          grid-area: newsletter;
          h2 {
            margin: 0 auto;
          }
        }
        .about {
          grid-area: about;
        }
        .community {
          grid-area: community;
        }
        .token {
          grid-area: token;
        }
        .social {
          grid-area: social;
          h2 {
            margin: 0 auto;
          }
        }
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        grid-template-areas:
          'social social'
          'newsletter newsletter'
          'community about '
          'token token';
        gap: 38px;
        margin-bottom: 20px;
      }
    }
  `,
  ContentCopy: styled.div`
    min-height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${props => props.theme.colors.gray[2]};
    @media (max-width: ${props => props.theme.viewport.desktop}) {
      min-height: 50px;
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;

      color: ${props => props.theme.colors.gray[2]};
      @media (max-width: ${props => props.theme.viewport.desktop}) {
        font-size: 16px;
        line-height: 100%;
      }
    }
  `,
  Nav: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 36px;
      color: ${props => props.theme.colors.black};
      @media (max-width: ${props => props.theme.viewport.desktop}) {
        font-size: 16px;
        line-height: 100%;
      }
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
    }
    nav {
      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          a {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            text-decoration: none;
            color: ${props => props.theme.colors.gray[2]};
            transition: color 0.5s;
            &:hover {
              color: ${props => props.theme.colors.black};
            }
            @media (max-width: ${props => props.theme.viewport.desktop}) {
              font-size: 16px;
              line-height: 100%;
            }
          }
        }
      }
    }
  `,
  InputBox: styled.div<{ hasError?: boolean }>`
    width: 100%;

    height: 52px;
    max-width: 569px;
    padding: 0px 5px 0px 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: ${props => props.theme.colors.gray[0]};
    border-radius: 800px;

    input.ant-input {
      background: transparent;
      border: none;
      color: ${props => props.theme.colors.gray[2]};
    }

    button {
      margin-left: auto;
    }

    ${props =>
      props.hasError &&
      css`
        border: 1px solid ${props.theme.colors.red.main};
      `}
  `,
  DefaultButton: styled(DefaultButton)`
    border-radius: 800px;
    @media (max-width: ${props => props.theme.viewport.desktop}) {
      span {
        font-size: 16px;
        line-height: 100%;
      }
    }
    @media (max-width: ${props => props.theme.viewport.tablet}) {
      span {
        font-size: 12px;
        line-height: 100%;
      }
    }
  `,
  Message: styled.span<{ hasError?: boolean }>`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    margin-left: 16px;
    ${props =>
      props.hasError
        ? css`
            color: ${props.theme.colors.red.main};
          `
        : css`
            color: ${props.theme.colors.green.main};
          `}
  `
}
