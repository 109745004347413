import { useQuery } from '@apollo/client'
import { BoxAsset } from '@appTypes/BoxTypes'
import { Erc721Item } from '@appTypes/WalletTypes'
import { chainConfig } from '@config'
import { NftByWalletData, NftByWalletVar, NFTS_BY_WALLET_QUERY } from '@graphql/nftfy/backend/shared/erc721/nftsByWallet'
import { getBoxMetadata } from '@services/BoxService'
import { getErc20Balance } from '@services/WalletService'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { backendV2 } from '../graphql/ClientGraphql'
import { erc721Service } from '../services/Erc721Service'
import { useGlobalConfig } from './ConfigHook'

export function useWalletNfts(account: string | undefined, chainIdList: number[]) {
  const { paginationLimit } = useGlobalConfig()
  const [offset, setOffset] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const { data, fetchMore, loading } = useQuery<NftByWalletData, NftByWalletVar>(NFTS_BY_WALLET_QUERY, {
    client: backendV2,
    variables: {
      ownerAddress: account?.length ? Web3.utils.toChecksumAddress(account) : '',
      offset,
      limit: 50,
      chainIdList
    },
    skip: !account
  })
  const loadMore = async () => {
    if (hasMore) {
      const isLoaded = (data?.erc721ByOwner.length || 0) - paginationLimit >= paginationLimit
      const newOffset = offset + paginationLimit
      const nextPageResult = await fetchMore({
        variables: {
          offset: isLoaded ? data?.erc721ByOwner.length || 0 : newOffset
        }
      })
      setOffset(newOffset)
      setHasMore(nextPageResult.data.erc721ByOwner.length === paginationLimit)
    }
  }

  useEffect(() => {
    setOffset(0)
    setHasMore(true)
  }, [chainIdList, data?.erc721ByOwner])

  return { nfts: data?.erc721ByOwner || [], loading, loadMore, hasMore }
}

export function useWalletNft(address: string, tokenId: string, chainId: number) {
  const [erc721, setErc721] = useState<Erc721Item | undefined>(undefined)
  const [isBox, setIsBox] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [nftsIntroBox, setNftsIntroBox] = useState<BoxAsset | undefined>(undefined)

  const config = chainConfig(chainId)

  useEffect(() => {
    const getNft = async () => {
      if (address && tokenId && chainId) {
        setIsLoading(true)
        const nft = await erc721Service(chainId).getErc721ItemMetadata(address, tokenId)
        if (address.toLowerCase() === config?.box.contract.toLowerCase()) {
          setIsBox(true)
          const nftsInsideBox = await getBoxMetadata(tokenId, chainId)
          setNftsIntroBox(nftsInsideBox)
        }
        if (nft) {
          setErc721(nft)
        } else {
          setErc721(undefined)
        }
        setIsLoading(false)
      }
    }
    getNft()
  }, [address, config, chainId, tokenId])

  return { erc721, isBox, nftsIntroBox, isLoading }
}

export function useErc20Balance(accountAddress: string, tokenAddress: string, tokenDecimals: number, chainId: number) {
  const [loading, setLoading] = useState(true)
  const [balance, setBalance] = useState('')

  useEffect(() => {
    const getBalance = async () => {
      if (accountAddress === '') {
        setBalance('0')
        setLoading(false)

        return
      }

      const amount = await getErc20Balance(accountAddress, tokenAddress, tokenDecimals, chainId)
      setBalance(amount.toString())
      setLoading(false)
    }
    getBalance()
  }, [accountAddress, chainId, tokenAddress, tokenDecimals])

  return { loading, balance }
}
