import { gql } from '@apollo/client'

export interface Erc20Liquidity {
  hasLiquidity: boolean
  priceDollar: string
  impliedValuation: number
  priceFraction: string
}

export interface QuoteErc20LiquidityVars {
  erc20id: string
  paymentTokenId: string
  paymentDecimals: number
  totalSupply: string
  chainId: number
}

export interface QuoteErc20LiquidityData {
  quoteErc20Liquidity: Erc20Liquidity
}

export const QUOTE_ERC20_LIQUIDITY = gql`
  query QuoteErc20Liquidity($erc20id: String!, $paymentTokenId: String!, $paymentDecimals: Int!, $totalSupply: String!, $chainId: Int!) {
    QuoteErc20Liquidity(
      erc20id: $erc20id
      paymentTokenId: $paymentTokenId
      paymentDecimals: $paymentDecimals
      totalSupply: $totalSupply
      chainId: $chainId
    ) {
      hasLiquidity
      impliedValuation
      priceDollar
      priceFraction
    }
  }
`
