import { gql } from '@apollo/client'

export interface NewsletterVars {
  email: string
}

export interface NewsletterData {
  contact: {
    hasError: boolean
    message: string
  }
}
export const NEWSLETTER = gql`
  mutation Newsletter($email: String!) {
    contact(email: $email) {
      hasError
      message
    }
  }
`
