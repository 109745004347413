import { gql } from '@apollo/client'

export interface QuotePriceDollarVars {
  paymentTokenId: string
  paymentTokenDecimals: number
  valueConvert: string
  chainId: number
}

export interface QuoteExchangeVars {
  amount: string
  amountDecimals: number
  fromTokenAddress: string
  toTokenAddress: string
  chainId: number
}
export interface QuoteExchangeData {
  quoteExchange: {
    amount: string
  }
}

export interface QuotePriceDollarData {
  quotePriceDollar: string
}

export const QUOTE_PRICE_DOLLAR_QUERY = gql`
  query QuoteExchange($amount: String!, $amountDecimals: Int!, $fromTokenAddress: String!, $toTokenAddress: String!, $chainId: Int!) {
    quoteExchange(
      amount: $amount
      amountDecimals: $amountDecimals
      fromTokenAddress: $fromTokenAddress
      toTokenAddress: $toTokenAddress
      chainId: $chainId
    ) {
      amount
    }
  }
`
