import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'

export interface ProductMenu {
  name: string
  description: string
  href: string
  activeHref: string[]
  enabled: boolean
  icon: string
}

interface HeaderProductMenuProps {
  open: boolean
  close: () => void
  menu: ProductMenu[]
}

export default function HeaderProductMenu({ open, close, menu }: HeaderProductMenuProps) {
  return (
    <>
      <Overlay onClick={close} className={`${open && 'open'}`} />
      <SubMenu className={`${open && 'open'}`}>
        <Menu>
          {menu.map(({ name, description, href, activeHref, enabled, icon }) => (
            <li key={name}>
              <Link href={href} className={` ${activeHref && 'active'} `}>
                <a>
                  <MenuItem className={`${!enabled && 'disabled'}`}>
                    <div>
                      <Image src={icon} />
                    </div>
                    <div>
                      <div>{name}</div>
                      <div>
                        {!enabled ? 'Coming Soon, ' : ''} {description}
                      </div>
                    </div>
                  </MenuItem>
                </a>
              </Link>
            </li>
          ))}
        </Menu>
      </SubMenu>
    </>
  )
}
const { SubMenu, Overlay, Menu, MenuItem } = {
  SubMenu: styled.div`
    visibility: hidden;
    position: absolute;
    top: 58px;
    width: 100%;
    max-width: 860px;
    background-color: ${props => props.theme.colors.gray[0]};
    border-radius: 1.6rem;
    z-index: 10;

    right: 0;
    padding: 1.6rem;

    &.open {
      visibility: visible;
    }
  `,
  Overlay: styled.div`
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    z-index: 0;
    &.open {
      display: block;
      height: 100vh;
      width: 100vw;
      z-index: -1;
    }
  `,
  Menu: styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.8rem;
    list-style-type: none;

    li {
      border-radius: 1.6rem;

      &:hover {
        background: ${props => props.theme.colors.white};
        cursor: pointer;
      }
    }
  `,
  MenuItem: styled.div`
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 1.6rem;
    padding: 1.2rem 2rem;

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    > div:nth-child(2) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.4rem;

      > div:nth-child(1) {
        font-size: 16px;
        color: ${props => props.theme.colors.black};
        font-weight: 400;
      }

      > div:nth-child(2) {
        font-size: 12px;
        color: ${props => props.theme.colors.gray[2]};
        font-weight: 300;
      }
    }
  `
}
