import { backendV1, backendV2, graphQlClient } from '@graphql/ClientGraphql'
import { BoxByIdItemsData, BoxByIdItemsVars, BOX_BY_ID_ITEMS_QUERY } from '@graphql/nftfy/backend/box/BoxByIdItems'
import { BoxByIdItemsCountData, BoxByIdItemsCountVars, BOX_BY_ID_ITEMS_COUNT_QUERY } from '@graphql/nftfy/backend/box/BoxByIdItemsCount'
import { Box, BoxByIdData, BoxByIDVars, BOX_BY_ID_QUERY } from '@graphql/nftfy/deprecated/box/BoxByIdQuery'
import { BoxByOwnerData, BoxByOwnerVars, BOX_BY_OWNER_QUERY } from '@graphql/nftfy/deprecated/box/BoxByOwnerQuery'
import { clearTransaction, handleTransaction, TransactionType } from '@graphql/variables/TransactionVariable'
import { AbiItem } from 'web3-utils'
import boxAbi from '../abi/boxAbi.json'
import { chainConfig } from '../config'
import { code } from '../messages'
import { BoxAsset } from '../types/BoxTypes'
import { Erc721Item } from '../types/WalletTypes'
import { erc721Service } from './Erc721Service'
import { initializeWeb3 } from './MultiWalletService'
import { isApprovedForAllErc721, setApprovalForAllErc721 } from './NftfyService'
import { notifyError, notifySuccess } from './NotificationService'
import { estimateGasPrice } from './UtilService'

interface BoxService {
  getNfts: (tokenId: string, chainId: number) => Promise<Erc721Item[]>
  getNftsCount: (tokenId: string, chainId: number) => Promise<number>
}

export const boxService = (): BoxService => {
  return {
    getNfts: async (tokenId: string, chainId: number) => {
      const { data, error } = await backendV2.query<BoxByIdItemsData, BoxByIdItemsVars>({
        query: BOX_BY_ID_ITEMS_QUERY,
        variables: { tokenId, chainId: Number(chainId) }
      })
      if (error) {
        return []
      }
      return data.erc721ItemsByBoxTokenId
    },
    getNftsCount: async (tokenId: string, chainId: number) => {
      const result = await backendV1.query<BoxByIdItemsCountData, BoxByIdItemsCountVars>({
        query: BOX_BY_ID_ITEMS_COUNT_QUERY,
        variables: { tokenId, chainId: Number(chainId) }
      })

      if (!result) {
        return 0
      }

      return result.data.boxItemsCount
    }
  }
}

/**
 * @deprecated This is trash implementation, all service need to be done using Encapsulation
 */
export const getBoxItemById = async (boxId: string, chainId: number): Promise<Box | undefined> => {
  const config = chainConfig(chainId)

  if (!config) {
    return undefined
  }

  const boxRequest = await (
    await graphQlClient(config.box.theGraph)
  ).query<BoxByIdData, BoxByIDVars>({
    query: BOX_BY_ID_QUERY,
    variables: { id: `${config.box.contract.toLowerCase()}#${boxId.toLowerCase()}` }
  })

  return boxRequest.data.box
}

/**
 * @deprecated This is trash implementation, all service need to be done using Encapsulation
 */
export const getBoxItems = async (accountAddress: string, chainId: number): Promise<(BoxAsset | undefined)[]> => {
  try {
    const config = chainConfig(chainId)

    if (!config) {
      return []
    }

    const listBox: BoxAsset[] = []

    const boxRequest = await (
      await graphQlClient(config.box.theGraph)
    ).query<BoxByOwnerData, BoxByOwnerVars>({
      query: BOX_BY_OWNER_QUERY,
      variables: { owner: accountAddress }
    })

    const items: Promise<BoxAsset | undefined>[] = await boxRequest.data.boxes.map(async box => {
      const metaBoxe = await getBoxMetadata(box.tokenId, chainId)
      metaBoxe && listBox.push(metaBoxe)
      return metaBoxe
    })

    return await Promise.all(items)
  } catch (error) {
    notifyError(code[5011], error)
    return []
  }
}

/**
 * @deprecated This is trash implementation, all service need to be done using Encapsulation
 */
export const getBoxMetadata = async (boxId: string, chainId: number): Promise<BoxAsset | undefined> => {
  const config = chainConfig(chainId)

  if (!config) {
    return undefined
  }

  const box = await getBoxItemById(boxId, chainId)

  if (!box) {
    return undefined
  }

  const boxMetadata = await erc721Service(chainId).getErc721ItemMetadata(config.box.contract, boxId)

  const nftsListPromises: Promise<Erc721Item | null>[] = []

  box.items &&
    box.items.forEach(boxItem => {
      nftsListPromises.push(erc721Service(chainId).getErc721ItemMetadata(boxItem.collection.id, boxItem.tokenId))
    })

  const nftsList = await Promise.all(nftsListPromises)
  const nfts = nftsList.map(nft => {
    return {
      tokenId: nft?.tokenId || '',
      image_url: nft?.metadata?.image || '',
      name: nft?.name || '',
      title: nft?.metadata?.name || '',
      address: nft?.address || '',
      nameContract: nft?.metadata?.asset_contract?.name || '',
      loading: false
    }
  })

  return {
    name: boxMetadata?.metadata?.name || '',
    author: boxMetadata?.metadata?.author || '',
    ownerOf: box?.owner,
    description: boxMetadata?.metadata?.description || '',
    image: boxMetadata?.metadata?.image || '',
    social_media: boxMetadata?.metadata?.social_media,
    web_site_url: boxMetadata?.metadata?.web_site_url,
    twitter: boxMetadata?.metadata?.twitter,
    telegram: boxMetadata?.metadata?.telegram,
    discord: boxMetadata?.metadata?.discord,
    instagram: boxMetadata?.metadata?.instagram,
    boxId,
    boxAddress: config.box.contract,
    nftCount: Number(box?.items.length),
    nfts
  }
}

/**
 * @deprecated This is trash implementation, all service need to be done using Encapsulation
 */
export const sendMintBox = async (cid: string, accountAddress: string, chainId: number) => {
  try {
    const config = chainConfig(chainId)

    if (!config) {
      return undefined
    }

    const web3 = initializeWeb3(chainId, false)
    const contractBox = new web3.eth.Contract(boxAbi as AbiItem[], config.box.contract)
    const result = contractBox.methods
      .mint(cid, accountAddress)
      .send({ from: accountAddress, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
        tx ? handleTransaction(tx, TransactionType.mintBox) : clearTransaction()
      })
    return result
  } catch (error) {
    notifyError(code[5011], error)
    return error
  }
}

/**
 * @deprecated This is trash implementation, all service need to be done using Encapsulation
 */
export const approve = async (tokenAddress: string, account: string, boxAddress: string, chainId: number) => {
  await setApprovalForAllErc721(tokenAddress, account, boxAddress, chainId)
  const result = await isApprovedForAllErc721(tokenAddress, account, boxAddress, chainId)
  result ? notifySuccess('Contract NFTs unlocked successfully') : notifyError(code[5011])

  return result
}

/**
 * @deprecated This is trash implementation, all service need to be done using Encapsulation
 */
export const addedNftInBox = async (boxId: number, tokenAddress: string, tokenId: string, chainId: number, account: string) => {
  const config = chainConfig(chainId)

  if (!config) {
    return false
  }

  const web3 = initializeWeb3(chainId, false)
  const contractBox = new web3.eth.Contract(boxAbi as AbiItem[], config.box.contract)
  const isApprovedForAll = await isApprovedForAllErc721(tokenAddress, account, config.box.contract, chainId)

  if (!isApprovedForAll) {
    await setApprovalForAllErc721(tokenAddress, account, config.box.contract, chainId)

    const contractApproved = await isApprovedForAllErc721(tokenAddress, account, config.box.contract, chainId)

    contractApproved ? notifySuccess('Contract NFTs unlocked successfully') : notifyError(code[5011])
  }

  const isApprovedForAll2 = await isApprovedForAllErc721(tokenAddress, account, config.box.contract, chainId)

  if (isApprovedForAll2) {
    try {
      await contractBox.methods
        .boxAddItem(boxId, tokenAddress, tokenId)
        .send({ from: account, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
          tx ? handleTransaction(tx, TransactionType.boxAddItem) : clearTransaction()
        })
      notifySuccess('NFT added in box')
      return true
    } catch (e) {
      notifyError(code[5011])
      return false
    }
  } else return false
}

/**
 * @deprecated This is trash implementation, all service need to be done using Encapsulation
 */
export const removeNftInBox = async (boxId: number, tokenAddress: string, tokenId: number, chainId: number, account: string) => {
  const config = chainConfig(chainId)

  if (!config) {
    return false
  }

  const web3 = initializeWeb3(chainId, false)
  const contractBox = new web3.eth.Contract(boxAbi as AbiItem[], config.box.contract)
  try {
    await contractBox.methods
      .boxRemoveItem(boxId, tokenAddress, tokenId, account)
      .send({ from: account, gasPrice: await estimateGasPrice(chainId) }, (_error: Error, tx: string) => {
        tx ? handleTransaction(tx, TransactionType.removeNftInBox) : clearTransaction()
      })
    notifySuccess('NFT removed from box')
    return true
  } catch (e) {
    notifyError(code[5011])
    return false
  }
}
