import { gql } from '@apollo/client'

export interface ProfileVars {
  walletAddress: string
}

export interface ProfileUpdateVars {
  accountId: string
  profile: Profile
}

export interface Profile {
  accountId?: string
  username?: string
  email?: string
  description?: string
  website?: string
  instagram?: string
  telegram?: string
  twitter?: string
  avatarId?: string
  avatarUrl?: string
  coverId?: string
  coverUrl?: string
  domain?: string
}

export interface ProfileData {
  profile: Profile
}

export const GET_ACCOUNT = gql`
  query GetAccount($walletAddress: String!) {
    profile(walletAddress: $walletAddress) {
      accountId
      username
      email
      description
      website
      instagram
      telegram
      twitter
      avatarId
      avatarUrl
      coverId
      coverUrl
      domain
    }
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation Mutation($accountId: String, $profile: ProfileInput) {
    updateProfile(accountId: $accountId, profile: $profile) {
      username
    }
  }
`
