import {
  NftCollection,
  NftCollectionData,
  NftCollectionVar,
  NFT_COLLECTION
} from '@graphql/nftfy/backend/rockpool/collection/nftCollection'
import { backendV2 } from '../graphql/ClientGraphql'
import {
  CollectionsBySlugData,
  CollectionsBySlugVars,
  GET_COLLECTIONS_BY_SLUG
} from '../graphql/nftfy/backend/rockpool/collection/CollectionBySlug'
import {
  Erc721ItemMetadataDataV2,
  Erc721ItemMetadataVarsV2,
  ERC721_ITEM_METADATA
} from '../graphql/nftfy/backend/shared/erc721/Erc721ItemMetadata'
import { Collection } from '../types/CollectionTypes'
import { Erc721Item } from '../types/WalletTypes'

interface Erc721Service {
  getErc721ItemMetadata(collectionAddress: string, tokenId: string): Promise<Erc721Item | null>
  getErc721Collection(collectionAddress: string): Promise<NftCollection | null>
  getErc721CollectionBySlug(slug: string): Promise<Collection | null>
}

export const erc721Service = (chainId: number): Erc721Service => {
  return {
    async getErc721ItemMetadata(contractAddress: string, tokenId: string) {
      try {
        const client = backendV2
        const queryV2 = await client.query<Erc721ItemMetadataDataV2, Erc721ItemMetadataVarsV2>({
          query: ERC721_ITEM_METADATA,
          variables: {
            chainId,
            contractAddress,
            tokenId
          }
        })

        if (!queryV2) {
          return null
        }

        const { data } = queryV2

        if (!data || !data.erc721) {
          return null
        }

        return data.erc721
      } catch (error) {
        throw new Error('Erc721ItemMetadata: error query ERC721_ITEM_METADATA')
      }
    },

    async getErc721Collection(collectionAddress: string): Promise<NftCollection | null> {
      const client = backendV2
      const queryV2 = await client.query<NftCollectionData, NftCollectionVar>({
        query: NFT_COLLECTION,
        variables: {
          chainId,
          collectionAddress
        }
      })

      if (!queryV2) {
        return null
      }
      const { data } = queryV2

      if (!data || !data.erc721Collection) {
        return null
      }
      return data.erc721Collection
    },
    async getErc721CollectionBySlug(slug: string) {
      const client = backendV2
      const queryV2 = await client.query<CollectionsBySlugData, CollectionsBySlugVars>({
        query: GET_COLLECTIONS_BY_SLUG,
        variables: {
          chainId,
          slug
        }
      })

      if (!queryV2) {
        return null
      }

      const { data, error } = queryV2

      if (error) {
        throw error
      }

      if (!data || !data.erc721CollectionBySlug) {
        return null
      }

      return data.erc721CollectionBySlug
    }
  }
}
