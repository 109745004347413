import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'

interface HeaderMenuProps {
  menu: { name: string; href: string; activeHref: string[]; activeParams?: string[] }[]
}
export default function HeaderMenu({ menu }: HeaderMenuProps) {
  const router = useRouter()

  return (
    <Menu>
      {menu.map(({ name, href, activeHref, activeParams }) => (
        <Link href={href} key={name}>
          <a
            className={` ${
              (activeHref.some(h => router.route === h) && !activeParams?.length) ||
              (activeHref.some(h => router.route === h) && activeParams?.length && router.asPath.includes(activeParams[0]))
                ? 'active'
                : ''
            }`}
          >
            {name}
          </a>
        </Link>
      ))}
    </Menu>
  )
}

const { Menu } = {
  Menu: styled.ul`
    display: inline-flex;
    justify-content: flex-end;
    gap: 1.6rem;

    a {
      color: ${props => props.theme.colors.black};
      font-size: 1.6rem;
      padding: 1rem 1.6rem;
      text-align: center;

      &:hover,
      &.active {
        background-color: ${props => props.theme.colors.gray[0]};
        border-radius: 3.2rem;
      }
    }
  `
}
