import collectionDefaultBlue from '@assets/icons/contract-info/collection-default/collectionDefaultBlue.svg'
import collectionDefaultRed from '@assets/icons/contract-info/collection-default/collectionDefaultRed.svg'
import profileDefaultBlue from '@assets/icons/contract-info/profile-default/profileDefaultBlue.svg'
import profileDefaultGreen from '@assets/icons/contract-info/profile-default/profileDefaultGreen.svg'
import profileDefaultPink from '@assets/icons/contract-info/profile-default/profileDefaultPink.svg'
import profileDefaultRed from '@assets/icons/contract-info/profile-default/profileDefaultRed.svg'
import profileDefaultYellow from '@assets/icons/contract-info/profile-default/profileDefaultYellow.svg'

import { allowedChains, NetworksId, NetworksName, openseaAllowedChains, SupportedNetworks } from '@config'
import { notifyError } from '@services/NotificationService'
import { userProfileService } from '@services/UserProfileService'
import BigNumber from 'bignumber.js'
import humanFormat from 'human-format'
import { jsNumberForAddress } from 'react-jazzicon'

import { initializeWeb3 } from './MultiWalletService'

export function isAllowedChain(chainId: number): boolean {
  return allowedChains.includes(chainId)
}
export const isOpenseaAllowedChains = (chainId: number): boolean => openseaAllowedChains.includes(chainId)

export const handleOpenSeaRedirection = (chainId: number, collectionAddress: string, tokenAddress: string) => {
  switch (chainId) {
    case SupportedNetworks.ethereum:
      return `https://opensea.io/assets/${collectionAddress}/${tokenAddress}`
      break
    case SupportedNetworks.goerli:
      return `https://testnets.opensea.io/assets/${collectionAddress}/${tokenAddress}`
      break
    case SupportedNetworks.polygon:
      return `https://opensea.io/assets/matic/${collectionAddress}/${tokenAddress}`
      break
    case SupportedNetworks.mumbai:
      return `https://testnets.opensea.io/assets/mumbai/${collectionAddress}/${tokenAddress}`
      break
    default:
      return '#'
      break
  }
}

export function formatShortAddress(addressFormat: string, firstPart = 5, lastPart?: number): string {
  if (!lastPart) {
    return `${addressFormat.slice(0, firstPart)}`
  }

  return `${addressFormat.slice(0, firstPart)}...${addressFormat.slice(lastPart)}`
}

export function formatShortAddressDescriptionNft(addressFormat: string): string {
  return `${addressFormat.slice(0, 9)}...`
}

export function formatShortAddressWallet(addressFormat: string, size = 6, ellipsis = false): string {
  return `${addressFormat.slice(0, size)}${ellipsis ? '...' : ''}`
}

export function regexValidateEmail(value: string) {
  const re = /\S+@\S+\.\S+/
  return re.test(value)
}

export function scale(input: BigNumber, decimalPlaces: number): BigNumber {
  const scalePow = new BigNumber(decimalPlaces.toString())
  const scaleMul = new BigNumber(10).pow(scalePow)
  return input.times(scaleMul)
}

export function valid(amount: string, decimals: number): boolean {
  const regex = new RegExp(`^\\d+${decimals > 0 ? `(\\.\\d{1,${decimals}})?` : ''}$`)
  return regex.test(amount)
}

export function units(coinsValue: string, decimals: number): string {
  if (!valid(coinsValue, decimals)) throw new Error('Invalid amount')
  let i = coinsValue.indexOf('.')
  if (i < 0) i = coinsValue.length
  const s = coinsValue.slice(i + 1)
  return coinsValue.slice(0, i) + s + '0'.repeat(decimals - s.length)
}

export function coins(unitsValue: string, decimals: number): string {
  if (!valid(unitsValue, 0)) throw new Error('Invalid amount')
  if (decimals === 0) return unitsValue
  const s = unitsValue.padStart(1 + decimals, '0')
  return `${s.slice(0, -decimals)}.${s.slice(-decimals)}`
}

export const imgLH3 = (url: string, width: number, height?: number): string => {
  if (height) {
    try {
      return url && url.includes('https://lh3') ? `${url.split('=s')[0]}=w${width}-h${height}-c` : url
    } catch (e) {
      notifyError('error image', e, true)
      return url
    }
  }

  return url && url.includes('https://lh3') ? `${url.split('=s')[0]}=s${width}-c` : url
}
/**
 * @deprecated treatment must be performed in the backend
 */
export const safeIpfsUrl = (url: string): string => {
  try {
    return url.includes('ipfs') ? `https://ipfs.io/ipfs/${url.split('/ipfs/')[url.split('/ipfs/').length - 1]}`.replace('ipfs://', '') : url
  } catch (error) {
    return url
  }
}
export const isUrl = (url: string): boolean => {
  try {
    return !!new URL(url)
  } catch {
    return false
  }
}
export const sleep = (ms = 100): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export const formatToLocaleString = (
  value: string | number | BigNumber,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number
) => {
  return new BigNumber(value).toNumber().toLocaleString('en-US', { maximumFractionDigits, minimumFractionDigits })
}

export const refreshProfile = (account: string, type: 'avatar' | 'cover') => {
  userProfileService().updateImageMetadata(account, type)
}

export function safeFractionsName(erc20Name: string): string {
  return erc20Name.replace('Shares', 'Fractions').replace('shares', 'fractions')
}

export function formatSymbol(tokenSymbol: string) {
  return tokenSymbol.length > 6 ? `${tokenSymbol.substr(0, 6)}...` : tokenSymbol
}

export function formatShortAccountName(addressFormat: string, size = 15): string {
  const keep = addressFormat.length > size ? '...' : ''
  return `${addressFormat.slice(0, size)}${keep}`
}
export function safeValue(value: string | undefined): string {
  if (!value) return '0'
  if (value[0] === '.') return `0${value}`
  if (value[0] === ',') return `0${value}`
  return value
}

export function getImageDefaultCollection(account?: string): string {
  const collectionsDefault = [collectionDefaultRed, collectionDefaultBlue]

  if (!account) {
    return collectionsDefault[Math.floor(Math.random() * (2 - 0) + 0)]
  }

  const formatAddress = jsNumberForAddress(account)

  return collectionsDefault[formatAddress % 2].src
}

export function getImageProfileDefault(account?: string): string {
  const profilesDefault = [profileDefaultBlue, profileDefaultGreen, profileDefaultPink, profileDefaultRed, profileDefaultYellow]
  if (!account) {
    return profilesDefault[0].src
  }
  const formatAddress = jsNumberForAddress(account)

  return profilesDefault[formatAddress % 4]
}

export function toHumanFormat(value: number): string {
  if (value === 0) {
    return '0'
  }
  if (value > 0 && value < 1) {
    return formatToLocaleString(value, 5)
  }

  return humanFormat(Number(value), {
    separator: ''
  })
    .replace('G', 'B') // Necessary since the prefix for short scale is B, not G: https://en.wikipedia.org/wiki/Metric_prefix
    .toLowerCase()
}

/**
 * @deprecated dont estimate gas on frontend
 */
export const estimateGasPrice = async (chainId: number) => {
  const web3 = initializeWeb3(chainId, true)
  if (chainId === SupportedNetworks.polygon || chainId === SupportedNetworks.rinkeby || chainId === SupportedNetworks.goerli) {
    const gasPrice = await web3.eth.getGasPrice()
    const estimatedGasPrice = new BigNumber(gasPrice).multipliedBy(1.1)
    return Math.ceil(estimatedGasPrice.toNumber()).toString()
  }

  return undefined
}

export const networkNameById = (network: number) => {
  switch (network) {
    case NetworksId.ethereum:
      return NetworksName.ethereum
    case NetworksId.rinkeby:
      return NetworksName.rinkeby
    case NetworksId.goerli:
      return NetworksName.goerli
    case NetworksId.mumbai:
      return NetworksName.mumbai
    case NetworksId.polygon:
      return NetworksName.polygon
    case NetworksId.binance_smart_chain:
      return NetworksName.binance_smart_chain
    case NetworksId.binance_testnet:
      return NetworksName.binance_testnet
    default:
      return NetworksName.ethereum
  }
}
