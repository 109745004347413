import { useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { chainConfig } from '../config'
import { backendV2 } from '../graphql/ClientGraphql'
import {
  Erc20Liquidity,
  QuoteErc20LiquidityData,
  QuoteErc20LiquidityVars,
  QUOTE_ERC20_LIQUIDITY
} from '../graphql/nftfy/backend/shared/quote/erc20Liquidity'
import { QuoteExchangeData, QuoteExchangeVars, QUOTE_PRICE_DOLLAR_QUERY } from '../graphql/nftfy/backend/shared/quote/priceDollar'
import { formatToLocaleString } from '../services/UtilService'

export function useErc20QuotationLiquidity(
  erc20id: string,
  paymentTokenId: string,
  paymentDecimals: number,
  totalSupply: string,
  chainId: number
) {
  const [erc20Liquidity, setErc20Liquidity] = useState<Erc20Liquidity>({
    hasLiquidity: false,
    impliedValuation: 0,
    priceDollar: '',
    priceFraction: ''
  })
  const { loading, data } = useQuery<QuoteErc20LiquidityData, QuoteErc20LiquidityVars>(QUOTE_ERC20_LIQUIDITY, {
    variables: {
      erc20id,
      paymentTokenId,
      paymentDecimals,
      totalSupply,
      chainId
    }
  })

  useEffect(() => {
    if (data && data?.quoteErc20Liquidity) {
      setErc20Liquidity(data?.quoteErc20Liquidity)
    }
  }, [data, data?.quoteErc20Liquidity])

  return { erc20Liquidity, loading }
}
export function useDollarQuotation(amount: string, chainId: number, paymentTokenAddress: string, paymentTokenDecimals: number) {
  const [price, setPrice] = useState('0')
  const [loading, setLoading] = useState(false)

  const getExchange = useCallback(async () => {
    setLoading(true)
    const config = chainConfig(chainId)
    const {
      data: { quoteExchange }
    } = await backendV2.query<QuoteExchangeData, QuoteExchangeVars>({
      query: QUOTE_PRICE_DOLLAR_QUERY,
      variables: {
        amount,
        amountDecimals: 18,
        fromTokenAddress: paymentTokenAddress,
        toTokenAddress: config.stablecoinAddress,
        chainId
      }
    })

    setPrice(quoteExchange?.amount)
    setLoading(false)
  }, [amount, chainId, paymentTokenAddress])

  useEffect(() => {
    if (amount && Number(amount) > 0 && chainId && paymentTokenAddress && paymentTokenDecimals) {
      getExchange()
    }
  }, [amount, chainId, getExchange, paymentTokenAddress, paymentTokenDecimals])

  return {
    priceDollar: formatToLocaleString(price, 2, 2),
    refetch: getExchange,
    loading
  }
}
