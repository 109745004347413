import { useEffect, useState } from 'react'

const SMALL_MOBILE = 375
const TABLET_WIDTH = 768
const SMALL_DESKTOP_WIDTH = 1024

const useBreakpoints = () => {
  const [isMobile, setisMobile] = useState<boolean>(false)
  const [isSmallMobile, setSmallMobile] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)
  const [isDesktop, setIsDesktop] = useState<boolean>(false)
  const [isBreakpointsReady, setIsBreakpointsReady] = useState<boolean>(false)
  const [isIos, setIsIos] = useState<boolean>(false)

  useEffect(() => {
    const updateBreakpoints = () => {
      const { screen } = window
      const { width } = screen
      setSmallMobile(width <= SMALL_MOBILE)
      setisMobile(width < TABLET_WIDTH)
      setIsTablet(width >= TABLET_WIDTH && width < SMALL_DESKTOP_WIDTH)
      setIsDesktop(width >= SMALL_DESKTOP_WIDTH)
    }

    updateBreakpoints()

    window.addEventListener('resize', updateBreakpoints)

    return () => window.removeEventListener('resize', updateBreakpoints)
  }, [])

  useEffect(() => {
    const breakpointIsDefined = [isMobile, isTablet, isDesktop].some(breakpoint => !!breakpoint)

    if (breakpointIsDefined && !!navigator.platform) {
      setIsBreakpointsReady(true)
    }
  }, [isBreakpointsReady, isMobile, isTablet, isDesktop])

  useEffect(() => {
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      setIsIos(true)
    }
  }, [])

  return {
    isBreakpointsReady,
    isIos,
    isDesktop,
    isTablet,
    isMobile,
    isSmallMobile
  }
}

export default useBreakpoints
